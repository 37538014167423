export const en = {
  'AppName': 'PG&E Claims',
  'AccountNumber': 'PG&E account number',
  "BusinessName": "Business name",
  "FirstName":"First name",
  "LastName":"Last name",
  "MailingAddress":"Mailing address",
  "AptSuite":"Apt/Suite",
  "City":"City",
  "ZIPCode":"ZIP code",
  "State":"State",
  "PrimaryPhone":"Primary phone number",
  "AlternatePhone":"Alternate phone number",
  "Email":"Email address",
  "ConfirmEmail":"Confirm email address",
  "AreYouPG&EEmployee":"Are you a PG&E employee?",
  "LANID":"LAN ID"

};