import { createTheme } from "@mui/material";

import React, { useEffect } from "react";

const theme = createTheme();

theme.typography.h6 = {
  fontSize: "1rem",
  "@media (min-width:600px)": {
    fontSize: "1rem",
    fontWeight: 100,
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "1rem",
    fontWeight: 200,
    color: "1B1C1D",
  },
};

interface Props {
  claimId: string;
}
export const Confirmation: React.FC<Props> = ({ claimId }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <h2 className="subheader">Claim received!</h2>

      <div className="formLabel">
        <strong>Your claim number is {claimId}.</strong>
      </div>

      <div className="description">
        Thank you for submitting your claim online. Please check your inbox for
        an email confirmation and print this page for your records.
      </div>

      <h3 className="formLabel">What to expect</h3>

      <div className="description">
        It is Pacific Gas and Electric Company's policy to promptly and fairly
        respond to customers' claims.
      </div>
      <div className="description">
        <ul>
          <li>
            Your{" "}
            <label style={{ fontWeight: "700" }}>
              <strong>
                claims representative will contact you within the next 3
                business days
              </strong>
            </label>{" "}
            and provide his/her contact information.
          </li>
        </ul>
      </div>
      <div className="description">
        <ul>
          <li>
            If you have questions or concerns regarding your claim please call
            our claims helpline at {""}
            <a href="tel:415-973-4548" style={{ color: "#07679B" }}>
              (415) 973-4548
            </a>
          </li>
        </ul>
      </div>
      <h3 className="formLabel">Supporting documents</h3>

      <div className="description">
        If you have not uploaded supporting documents, please submit them
        through one of these methods:
      </div>

      <div>
        <label className="formLabel">1. Email: </label>
        <a
          href={"mailto:claimdocs@pge.com?subject=" + claimId}
          style={{ color: "#07679B" }}
          target="_blank"
          rel="noreferrer"
        >
          claimsdocs@pge.com?subject={claimId}
        </a>
      </div>
      <div>
        <label className="formLabel">2. Fax: </label>
        <label className="description">
          {" "}
          (925) 459-7326 with a copy of this confirmation page
        </label>
      </div>

      <div>
        <label className="formLabel">3. US mail: </label>
        <label className="description"> Pacific Gas and Electric Company</label>
      </div>
      <div
        style={{
          marginLeft: "95px",
        }}
      >
        <label className="description">Attn: Law Claims Department</label>
      </div>
      <div
        style={{
          marginLeft: "95px",
        }}
      >
        <label className="description">300 Lakeside Drive</label>
      </div>
      <div
        style={{
          marginLeft: "95px",
        }}
      >
        <label className="description"> Oakland, CA 94612</label>
      </div>
      <br />

      <div className="description" style={{ fontWeight: "700" }}>
        <strong>
          Please include your claim number with all correspondence.
        </strong>
      </div>

      <div className="description">
        Thank you for your courtesy and cooperation in this matter.
      </div>

      <br />
      <div className="description">Sincerely,</div>

      <div className="description">PG&E Claims Team</div>
    </div>
  );
};
