import React, { useState } from "react";
import "./App.css";
import { ClaimsFooter } from "./components/Footer/ClaimsFooter";
import ClaimsHeader from "./components/Header/ClaimsHeader";
import { Box, Grid } from "@mui/material";
import { RouterDom } from "./components/Router/RouterDom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Container from "@mui/material/Container";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0C7DBB",
    },
  },
});

export const App: React.FC = () => {
  const [activeStep, setActiveStep] = useState(1);

  return (
    <ThemeProvider theme={theme}>
      <Box
        style={{
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <ClaimsHeader />
        <Container maxWidth="lg">
          <Grid
            item
            xl={9}
            lg={9}
            md={9}
            sm={9}
            xs={11}
            sx={{ marginBottom: "100px" }}
          >
            <RouterDom setActiveStep={setActiveStep} activeStep={activeStep} />
          </Grid>
        </Container>
        <div style={{ marginTop: "auto" }}>
          <ClaimsFooter />
        </div>
      </Box>
    </ThemeProvider>
  );
};

export const UnderConstruction: React.FC = () => {
  return (
    <div style={{ marginLeft: "50px", marginTop: "30px" }}>
      <h1>Site unavailable</h1>
      <div style={{ marginBottom: "80px" }}>
        We're sorry! PG&E Claims is temporarily unavailable. We apologize for
        any inconvenience.
      </div>
      <div>©{new Date().getFullYear()} Pacific Gas and Electric Company</div>
    </div>
  );
};
