import { Alert } from "@mui/material";
import * as React from "react";
import { ReactComponent as AlertIcon } from "../../src/Assets/Banner-Info.svg";

export const InfoBanner: React.FC = () => {
  return (
    <div className="infoBanner">
      <Alert
        icon={<div>{<AlertIcon />}</div>}
        sx={{
          background: "white",
          color: "black",
          marginTop: "10px",
          marginBottom: "15px",
          padding: "0px 0px",
        }}
      >
        <div>
          If you have trouble submitting your claim online, please contact
          PG&E’s claims helpline at{" "}
          <a href="tel:415-973-4548" style={{ color: "#07679B" }}>
            (415) 973-4548
          </a>{" "}
          for assistance.
        </div>
      </Alert>
    </div>
  );
};
