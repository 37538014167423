import * as React from "react";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import { Checkbox, Box, FormControlLabel } from "@mui/material";
import { stateConfig } from "../../../configs/state";
import { WitnessList } from "../../../types/user-data";
import { useForm } from "react-hook-form";
import "../../styles.css";
import classNames from "classnames";
import {
  BootstrapDialogTitle,
  phoneFormat,
} from "../../../utils/common-function";
import { useState } from "react";
import useCheckScreenSize from "../../../utils/checkScreenSize";

export interface Props {
  setWitnessPopupData: (fields: Partial<WitnessList>) => void;
  witnessLength: number;
  setShowAlert: (value: boolean) => void;
}

const INITIAL_DATA: WitnessList = {
  address_line1: "",
  first_name: "",
  last_name: "",
  city: "",
  pge_employee: "",
  phone_number: "",
  state: "CA",
  zip_code: "",
};

export const AddWitnessPopup: React.FC<Props> = ({
  setWitnessPopupData,
  witnessLength,
  setShowAlert,
}) => {
  const [open, setOpen] = React.useState(false);

  const isMobile = useCheckScreenSize({ breakpoint: 850 });

  const [addWitnessRowData, SetAddWitnessRowData] = useState(INITIAL_DATA);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<WitnessList>({
    mode: "onTouched",
    reValidateMode: "onChange",
  });

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    SetAddWitnessRowData(INITIAL_DATA);
  };

  const handleCheckBox = (event: any) => {
    if (event.target.checked === true) {
      addFormData({ pge_employee: "Yes" });
    } else {
      addFormData({ pge_employee: "No" });
    }
  };
  const addFormData = (fields: any) => {
    SetAddWitnessRowData((prev: any) => {
      return { ...prev, ...fields };
    });
  };
  const handleSubmitPopup = (witnessData: any) => {
    if (
      addWitnessRowData.pge_employee === "" ||
      addWitnessRowData.pge_employee === undefined
    ) {
      addWitnessRowData.pge_employee = "No";
    }
    if (addWitnessRowData.phone_number.length !== 14) {
      addWitnessRowData.phone_number = "";
    }
    if (witnessLength < 3) {
      setWitnessPopupData(addWitnessRowData);
      setOpen(false);
      setShowAlert(false);
    } else {
      setShowAlert(true);
      setOpen(false);
    }

    SetAddWitnessRowData(INITIAL_DATA);

    reset();
  };

  return (
    <div>
      <div className="witnessButtonPadding">
        <input
          type="button"
          onClick={handleClickOpen}
          className="blueButton"
          value="Add a witness"
        />
      </div>
      <Dialog
        maxWidth={"md"}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            m: "auto",
            width: "fit-content",
          }}
          className={classNames({ popup: !isMobile })}
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
          >
            <h2 className="subheader">Add a witness</h2>

            <div>*required field </div>
          </BootstrapDialogTitle>

          <form onSubmit={handleSubmit(handleSubmitPopup)}>
            <DialogContent>
              <div className={classNames({ row: !isMobile })}>
                <div className={classNames("column", { twoFields: !isMobile })}>
                  <div className="formLabelPopoup">
                    <label htmlFor="FirstName">*First name</label>
                  </div>
                  <input
                    id="FirstName"
                    maxLength={50}
                    className={classNames("input", {
                      popupInputMobile: isMobile,
                      errorInputNoWidth: errors.first_name && isMobile,
                      errorInput: errors.first_name && !isMobile,
                      input: !isMobile,
                    })}
                    {...register("first_name", {
                      required: true,
                      onChange: (e) => {
                        addFormData({
                          first_name: e.target.value
                            .trimStart()
                            .replace(/[-~`!^;|\\":<>?()@$*{}+=]/gi, ""),
                        });
                      },
                      validate: (value) => value !== "",
                    })}
                    value={addWitnessRowData.first_name}
                    aria-describedby="FirstName_error"
                  />
                  {errors.first_name ? (
                    <div className="errorText" id="FirstName_error">
                      Error: First name is required
                    </div>
                  ) : null}
                </div>

                <div className="column">
                  <div className="formLabelPopoup">
                    <label htmlFor="LastName">*Last name</label>
                  </div>
                  <input
                    id="LastName"
                    maxLength={50}
                    className={classNames("input", {
                      popupInputMobile: isMobile,
                      errorInputNoWidth: errors.last_name && isMobile,
                      errorInput: errors.last_name && !isMobile,
                      input: !isMobile,
                    })}
                    {...register("last_name", {
                      required: true,
                      onChange: (e) => {
                        addFormData({
                          last_name: e.target.value
                            .trimStart()
                            .replace(/[-~`!^;|\\":<>?()@$*{}+=]/gi, ""),
                        });
                      },
                      validate: (value) => value !== "",
                    })}
                    value={addWitnessRowData.last_name}
                    aria-describedby="LastName_error"
                  />
                  {errors.last_name ? (
                    <div className="errorText" id="LastName_error">
                      Error: Last name is required
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="formLabelPopoup">
                <label htmlFor="Address">Address</label>
              </div>
              <input
                id="Address"
                maxLength={100}
                className={classNames("input", { popupInputMobile: isMobile })}
                {...register("address_line1")}
                value={addWitnessRowData.address_line1}
                onChange={(e) =>
                  addFormData({
                    address_line1: e.target.value
                      .trimStart()
                      .replace(/[-~`!^;|\\":<>?()@$*{}+=]/gi, ""),
                  })
                }
              />

              <div className={classNames({ row: !isMobile })}>
                <div className={classNames("column", !isMobile && "twoFields")}>
                  <div className="formLabelPopoup">
                    <label htmlFor="City">City</label>
                  </div>
                  <input
                    id="City"
                    maxLength={50}
                    className={classNames("input", {
                      popupInputMobile: isMobile,
                    })}
                    {...register("city", {
                      onChange: (e) =>
                        addFormData({
                          city: e.target.value
                            .trimStart()
                            .replace(/[-~`!^;|\\":<>?()@$*{}+=]/gi, ""),
                        }),
                    })}
                    value={addWitnessRowData.city}
                  />
                </div>
                <div className="column" style={{ marginRight: "2%" }}>
                  <div className="formLabelPopoup">
                    <label htmlFor="ZIP">ZIP code</label>
                  </div>
                  <input
                    id="ZIP"
                    maxLength={5}
                    className={classNames(
                      {
                        errorInputSmall:
                          errors.zip_code &&
                          addWitnessRowData.zip_code.length !== 5,
                      },
                      "inputSmall"
                    )}
                    {...register("zip_code", {
                      pattern: {
                        value: /\d{5}/,
                        message: "Error: Please enter a valid ZIP code",
                      },
                      onChange: (e) =>
                        addFormData({
                          zip_code: e.target.value
                            .trim()
                            .replace(/[^0-9 ]/gi, ""),
                        }),
                    })}
                    value={addWitnessRowData.zip_code}
                    aria-describedby="zip_code_error"
                  />
                  {errors.zip_code &&
                  addWitnessRowData.zip_code.length !== 5 ? (
                    <div className="errorText" id="zip_code_error">
                      Error: Please enter <br />a valid ZIP code
                      <br /> (ex: 12345, 94538)
                    </div>
                  ) : null}
                </div>
                <div className="column" style={{ marginRight: "3%" }}>
                  <div className="formLabelPopoup">
                    <label htmlFor="state">State</label>
                  </div>
                  <select
                    className="inputSmallState"
                    id="state"
                    defaultValue="CA"
                    value={addWitnessRowData.state}
                    onChange={(e) => addFormData({ state: e.target.value })}
                  >
                    {stateConfig.map((state, index) => (
                      <option key={index} value={state.key}>
                        {" "}
                        {state.key}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="formLabelPopoup">
                <label htmlFor="Phone">Phone number</label>
              </div>
              <input
                id="Phone"
                className={classNames("input", {
                  popupInputMobile: isMobile,
                  errorInput: errors.phone_number,
                })}
                maxLength={14}
                value={phoneFormat(addWitnessRowData.phone_number)}
                {...register("phone_number", {
                  pattern: {
                    value: /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/,
                    message: "Error: Please enter a valid phone number",
                  },
                  onChange: (e) => {
                    addFormData({ phone_number: e.target.value });
                  },
                })}
                aria-describedby="Phone_error"
              />
              <br />
              {errors.phone_number &&
              addWitnessRowData.phone_number.length !== 14 ? (
                <div className="errorText" id="Phone_error">
                  Error: You must enter a 10-digit phone number
                </div>
              ) : null}

              <div className="formLabelPopoup" style={{ marginBottom: "10px" }}>
                <FormControlLabel
                  control={<Checkbox />}
                  {...(register("pge_employee"),
                  {
                    onChange: (e) => handleCheckBox(e),
                  })}
                  label="PG&E employee"
                />
              </div>
              <button className="yellowButton" type="submit">
                Add
              </button>
              <div className="secondButtonDiv">
                <button onClick={handleClose} className="secondButton">
                  Cancel
                </button>
              </div>
            </DialogContent>
          </form>
        </Box>
      </Dialog>
    </div>
  );
};
