import React, { useState, useEffect } from "react";

import { UserData, WitnessList } from "../../../types/user-data";

import "../../styles.css";
import { INITIAL_DATA } from "../../../configs/initial-data";
import useCheckScreenSize from "../../../utils/checkScreenSize";

import { MobileWitnessTableView, TableWitnessView } from "./WitnessTable";
import { useNavigate } from "react-router-dom";
import { ReactComponent as AlertIcon } from "../../../Assets/Alert.svg";
import { Alert, Stack } from "@mui/material";
import { InfoBanner } from "../../../utils/InfoBanner";

interface Props extends UserData {
  data: Partial<UserData>;
  witnessListData: WitnessList[];
  setWitnessListData: (fields: any) => void;
}
export const Witnesses: React.FC<Props> = ({
  data,
  witnessListData,
  setWitnessListData,
}) => {
  const navigate = useNavigate();
  const [showAlert, setShowAlert] = useState(false);

  const isMobile = useCheckScreenSize({ breakpoint: 800 });

  const handleNext = () => {
    navigate("../SupportingDocuments");
  };
  const handleBack = () => {
    navigate("../ClaimDetails");
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <form>
        <div>
          <Stack sx={{ width: "100%" }} spacing={2}>
            {showAlert ? (
              <Alert
                icon={
                  <div>
                    <AlertIcon />
                  </div>
                }
                sx={{
                  background: "#F7D8D8",
                  color: "black",
                  boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.07)",
                  fontWeight: "bold",
                  marginTop: "10px",
                  marginBottom: "15px",
                }}
              >
                <div style={{ marginTop: "3px" }}>
                  Error: You may add up to three witnesses
                </div>
              </Alert>
            ) : null}
          </Stack>
          <h2 className="subheader">Witnesses (Optional)</h2>

          <div className="description">
            If there were no witnesses, you may skip this step. This is typical
            for injury-related claims. You may add up to three witnesses.
          </div>
          {witnessListData.length < 1 && (
            <div>Results will appear in a table below.</div>
          )}

          <br />
          {isMobile ? (
            <MobileWitnessTableView
              {...INITIAL_DATA}
              witnessListData={witnessListData}
              {...witnessListData}
              setWitnessListData={setWitnessListData}
              setShowAlert={setShowAlert}
            />
          ) : (
            <TableWitnessView
              {...INITIAL_DATA}
              witnessListData={witnessListData}
              {...witnessListData}
              setWitnessListData={setWitnessListData}
              setShowAlert={setShowAlert}
            />
          )}

          <br />

          <button className="yellowButton" type="submit" onClick={handleNext}>
            Next
          </button>
          <div className="secondButtonDiv">
            <button onClick={handleBack} className="secondButton">
              Back
            </button>
          </div>
        </div>
      </form>
      <InfoBanner />
    </>
  );
};
