import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import { ItemList } from "../../../types/user-data";
import { ReactComponent as EditIcon } from "../../../Assets/Edit.svg";
import useCheckScreenSize from "../../../utils/checkScreenSize";
import { useEffect, useState } from "react";
import classNames from "classnames";
import { BootstrapDialogTitle } from "../../../utils/common-function";

export interface Props {
  ItemListData: ItemList[];
  updateItemListFields: (fields: any) => void;
  index: number;
  row: ItemList;
}

export const EditItemPopup: React.FC<Props> = ({
  ItemListData,
  updateItemListFields,
  index,
  row,
}) => {
  const [open, setOpen] = React.useState(false);
  const [addItemRowData, SetAddItemRowData] = useState(row);
  const [submitted, setSubmitted] = useState(false);
  const isMobile = useCheckScreenSize({ breakpoint: 850 });
  const handleClickOpen = () => {
    setOpen(true);
  };
  useEffect(() => {
    SetAddItemRowData(row);
  }, [row]);
  const handleClose = () => {
    setOpen(false);
  };
  const UpdateFormData = (fields: any) => {
    SetAddItemRowData((prev: any) => {
      return { ...prev, ...fields };
    });
  };
  const handleSubmitPopup = (data: any) => {
    setOpen(false);
    let newArr = [...ItemListData];
    newArr[index] = data;
    updateItemListFields(newArr);
  };
  const handleSubmit = () => {
    setSubmitted(true);
    if (
      addItemRowData.amount &&
      addItemRowData.description &&
      !(parseFloat(addItemRowData.amount!) > 10000000) &&
      !(addItemRowData.amount?.toString().split(".")[1]?.length! > 2)
    ) {
      handleSubmitPopup(addItemRowData);
    }
  };
  return (
    <div>
      <button
        aria-label="Edit"
        id="alert-dialog-title"
        onClick={handleClickOpen}
        className="editButton"
        type="button"
      >
        <EditIcon />
      </button>

      <Dialog
        maxWidth={"md"}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            m: "auto",
            width: "fit-content",
          }}
          className={classNames({ popup: !isMobile })}
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
          >
            <h2 className="subheader"> Update item</h2>

            <Typography gutterBottom>*required field </Typography>
          </BootstrapDialogTitle>

          <DialogContent>
            <br />

            <div className="formLabelPopoup">
              <label htmlFor="Make">Make/brand</label>
            </div>
            <input
              id="Make"
              maxLength={20}
              className={classNames(
                { popupInputMobileItem: isMobile },
                "input"
              )}
              value={addItemRowData.make}
              onChange={(e) =>
                UpdateFormData({
                  make: e.target.value
                    .trimStart()
                    .replace(/[-~`!^;|\\":<>?()@$*{}+=]/gi, ""),
                })
              }
            />

            <br />

            <div className="formLabelPopoup">
              <label htmlFor="Model">Model</label>
            </div>

            <input
              id="Model"
              maxLength={20}
              className={classNames(
                { popupInputMobileItem: isMobile },
                "input"
              )}
              value={addItemRowData.model}
              onChange={(e) =>
                UpdateFormData({
                  model: e.target.value
                    .trimStart()
                    .replace(/[-~`!^;|\\":<>?()@$*{}+=]/gi, ""),
                })
              }
            />

            <div className={classNames(!isMobile && "row")}>
              <div className={classNames(!isMobile && "twoFields")}>
                <div className="formLabelPopoup">
                  <label htmlFor="Year">Year</label>
                </div>
                <input
                  id="Year"
                  maxLength={20}
                  className={classNames(
                    {
                      popupInputHalf: !isMobile,
                      popupInputMobileItem: isMobile,
                    },
                    "input"
                  )}
                  value={addItemRowData.age}
                  onChange={(e) => UpdateFormData({ age: e.target.value })}
                />
              </div>
              <div>
                <div className="formLabelPopoup">
                  <label htmlFor="Amount">*Amount</label>
                </div>
                <div className="input-icon">
                  <input
                    className={classNames(
                      "adornedInputPopup",
                      !addItemRowData.amount && submitted && isMobile
                        ? "errorpopupInputMobileItem"
                        : "inputpopupInputMobileItem",
                      !addItemRowData.amount && submitted && !isMobile
                        ? "errorpopupInputHalf"
                        : "popupInputHalf",
                      parseFloat(addItemRowData.amount!) > 10000000 ||
                        addItemRowData.amount?.toString().split(".")[1]
                          ?.length! > 2
                        ? "errorpopupInputHalf"
                        : "popupInputHalf"
                    )}
                    id="Amount"
                    maxLength={11}
                    onChange={(e) => {
                      UpdateFormData({
                        amount: e.target.value.replace(/[^0-9.]/gi, ""),
                      });
                    }}
                    value={addItemRowData.amount}
                    aria-describedby="Amount_error"
                  />
                  <i>$</i>
                </div>
                {!addItemRowData.amount && submitted ? (
                  <div className="errorText" id="Amount_error">
                    Error: Amount is required
                  </div>
                ) : null}
                {(parseFloat(addItemRowData.amount!) > 10000000 ||
                  addItemRowData.amount?.toString().split(".")[1]?.length! >
                    2) && (
                  <div
                    className="errorText"
                    id="Amount_error"
                    style={{ width: "150px" }}
                  >
                    Error: Invalid amount entry. Please enter value between 0.00
                    and 10000000.00
                  </div>
                )}
              </div>
            </div>
            <div className="formLabelPopoup">
              <label htmlFor="Description">*Item description</label>
            </div>
            <textarea
              id="Description"
              className={classNames(
                !addItemRowData.description && submitted
                  ? "errorTextarea"
                  : "textarea",
                !addItemRowData.description &&
                  submitted &&
                  isMobile &&
                  "popupInputMobileItem",

                isMobile && "popupInputMobileItem"
              )}
              style={{ marginBottom: "10px" }}
              maxLength={100}
              rows={6}
              cols={37}
              onChange={(e) => {
                UpdateFormData({ description: e.target.value });
              }}
              value={addItemRowData.description}
              aria-describedby="Description_error"
            />
            {!addItemRowData.description && submitted ? (
              <div className="errorText" id="Description_error">
                Error: Item description is required.
              </div>
            ) : null}
            <div style={{ marginTop: "10px" }}>
              <button
                className={classNames(
                  { popupInputMobileItem: isMobile },
                  "yellowButton"
                )}
                onClick={handleSubmit}
              >
                Submit
              </button>
            </div>
            <div className="secondButtonDiv">
              <button onClick={handleClose} className="secondButton">
                Cancel
              </button>
            </div>
          </DialogContent>
        </Box>
      </Dialog>
    </div>
  );
};
