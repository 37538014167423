import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses, TooltipProps } from "@mui/material/Tooltip";
import { ReactComponent as Info } from "../Info.svg";
import "./StyledTooltip.css";

interface NewTooltipProps {
  title: any;
  id: string;
}
const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltipArrow}`]: {
    backgroundColor: "#19335E",
    color: "white",
    paddingLeft: "12px",
    paddingRight: "12px",
    paddingTop: "8px",
    paddingBottom: "8px",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "28px",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#19335E",
  },
}));

function NewTooltip(props: NewTooltipProps) {
  const { title, id } = props;

  return (
    <StyledTooltip
      title={title}
      placement="top"
      role="tooltip"
      arrow
      tabIndex={0}
    >
      <span className="tooltip" aria-describedby={id}>
        <Info />
      </span>
    </StyledTooltip>
  );
}

export default NewTooltip;
