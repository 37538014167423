import * as React from "react";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import { Checkbox, FormControlLabel, Box } from "@mui/material";
import { stateConfig } from "../../../configs/state";
import { WitnessList } from "../../../types/user-data";
import "../../styles.css";
import { ReactComponent as EditIcon } from "../../../Assets/Edit.svg";
import { useEffect, useState } from "react";
import {
  BootstrapDialogTitle,
  phoneFormat,
} from "../../../utils/common-function";
import classNames from "classnames";
import useCheckScreenSize from "../../../utils/checkScreenSize";

export interface Props {
  witnessListData: WitnessList[];
  setWitnessListData: (fields: any) => void;
  index: number;
  row: WitnessList;
}

export const UpdateWitnessPopup: React.FC<Props> = ({
  witnessListData,
  setWitnessListData,
  index,
  row,
}) => {
  const [open, setOpen] = React.useState(false);
  const [addItemRowData, SetAddItemRowData] = useState(row);
  const [submitted, setSubmitted] = useState(false);
  const isMobile = useCheckScreenSize({ breakpoint: 850 });
  const handleClickOpen = () => {
    setOpen(true);
  };
  useEffect(() => {
    SetAddItemRowData(row);
  }, [row]);
  const handleClose = () => {
    setOpen(false);
  };
  const UpdateFormData = (fields: any) => {
    SetAddItemRowData((prev: any) => {
      return { ...prev, ...fields };
    });
  };
  const handleSubmitPopup = (data: any) => {
    setOpen(false);
    let newArr = [...witnessListData];
    if (addItemRowData.pge_employee === "Yes") {
      data.pge_employee = "Yes";
    } else {
      data.pge_employee = "No";
    }
    newArr[index] = data;
    setWitnessListData(newArr);
  };
  const handleSubmit = () => {
    setSubmitted(true);
    if (addItemRowData.zip_code.length !== 5) {
      addItemRowData.zip_code = "";
    }
    if (addItemRowData.first_name && addItemRowData.last_name) {
      if (addItemRowData.phone_number.length > 0) {
        if (addItemRowData.phone_number.length === 14) {
          handleSubmitPopup(addItemRowData);
        }
      } else {
        handleSubmitPopup(addItemRowData);
      }
    }
  };

  const handleCheckBox = (event: any) => {
    if (event.target.checked === true) {
      UpdateFormData({ pge_employee: "Yes" });
    } else {
      UpdateFormData({ pge_employee: "No" });
    }
  };
  return (
    <div>
      <button
        aria-label="Edit"
        id="alert-dialog-title"
        onClick={handleClickOpen}
        className="editButton"
        type="button"
      >
        <EditIcon />
      </button>

      <Dialog
        maxWidth={"md"}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            m: "auto",
            width: "fit-content",
          }}
          className={classNames({ popup: !isMobile })}
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
          >
            <h2 className="subheader"> Update witness</h2>

            <Typography gutterBottom>*required field </Typography>
          </BootstrapDialogTitle>

          <DialogContent>
            <div className={classNames({ row: !isMobile })}>
              <div className={classNames("column", { twoFields: !isMobile })}>
                <div className="formLabelPopoup">
                  <label htmlFor="FirstName">*First name:</label>
                </div>

                <input
                  id="FirstName"
                  className={classNames("input", {
                    popupInputMobile: isMobile,
                    errorInputNoWidth:
                      !addItemRowData.first_name && submitted && isMobile,
                    errorInput:
                      !addItemRowData.first_name && submitted && !isMobile,
                    input: !isMobile,
                  })}
                  value={addItemRowData.first_name}
                  onChange={(e) =>
                    UpdateFormData({
                      first_name: e.target.value
                        .trimStart()
                        .replace(/[-~`!^;|\\":<>?()@$*{}+=]/gi, ""),
                    })
                  }
                  aria-describedby="FirstName_error"
                />

                {!addItemRowData.first_name && submitted ? (
                  <div className="errorText" id="FirstName_error">
                    Error: First name is required
                  </div>
                ) : null}
              </div>

              <div className="column">
                <div className="formLabelPopoup">
                  <label htmlFor="LastName">*Last name</label>
                </div>
                <input
                  id="LastName"
                  className={classNames("input", {
                    popupInputMobile: isMobile,
                    errorInputNoWidth:
                      !addItemRowData.last_name && submitted && isMobile,
                    errorInput:
                      !addItemRowData.last_name && submitted && !isMobile,
                    input: !isMobile,
                  })}
                  value={addItemRowData.last_name}
                  onChange={(e) =>
                    UpdateFormData({
                      last_name: e.target.value
                        .trimStart()
                        .replace(/[-~`!^;|\\":<>?()@$*{}+=]/gi, ""),
                    })
                  }
                  aria-describedby="LastName_error"
                />

                {!addItemRowData.last_name && submitted ? (
                  <div className="errorText" id="LastName_error">
                    Error: Last name is required
                  </div>
                ) : null}
              </div>
            </div>
            <div className="formLabelPopoup">
              <label htmlFor="Address">Address</label>
            </div>
            <input
              id="Address"
              className={classNames("input", { popupInputMobile: isMobile })}
              value={addItemRowData.address_line1}
              onChange={(e) =>
                UpdateFormData({
                  address_line1: e.target.value
                    .trimStart()
                    .replace(/[-~`!^;|\\":<>?()@$*{}+=]/gi, ""),
                })
              }
            />

            <br />
            <div className={classNames({ row: !isMobile })}>
              <div className={classNames("column", !isMobile && "twoFields")}>
                <div className="formLabelPopoup">
                  <label htmlFor="City">City</label>
                </div>
                <input
                  id="City"
                  className={classNames("input", {
                    popupInputMobile: isMobile,
                  })}
                  value={addItemRowData.city}
                  onChange={(e) =>
                    UpdateFormData({
                      city: e.target.value
                        .trimStart()
                        .replace(/[-~`!^;|\\":<>?()@$*{}+=]/gi, ""),
                    })
                  }
                />
              </div>

              <div className="column" style={{ marginRight: "2%" }}>
                <div className="formLabelPopoup">
                  <label htmlFor="ZIP">ZIP code</label>
                </div>
                <input
                  id="ZIP"
                  maxLength={5}
                  pattern="/\d{5}/"
                  className={classNames(
                    {
                      errorInputSmall:
                        addItemRowData.zip_code.length > 0 &&
                        addItemRowData.zip_code.length !== 5,
                    },
                    "inputSmall"
                  )}
                  value={addItemRowData.zip_code}
                  onChange={(e) =>
                    UpdateFormData({
                      zip_code: e.target.value.trim().replace(/[^0-9 ]/gi, ""),
                    })
                  }
                  aria-describedby="zip_code_error"
                />
                {addItemRowData.zip_code.length > 0 &&
                addItemRowData.zip_code.length !== 5 ? (
                  <div className="errorText" id="zip_code_error">
                    Error: Please enter <br />a valid ZIP code
                    <br /> (ex: 12345, 94538)
                  </div>
                ) : null}
              </div>

              <div className="column" style={{ marginRight: "3%" }}>
                <div className="formLabelPopoup">
                  <label htmlFor="state">State</label>
                </div>
                <select
                  className="inputSmallState"
                  id="state"
                  defaultValue={addItemRowData.state}
                  value={addItemRowData.state}
                  onChange={(e) =>
                    UpdateFormData({
                      state: e.target.value,
                    })
                  }
                >
                  {stateConfig.map((state, index) => (
                    <option key={index} value={state.key}>
                      {" "}
                      {state.key}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="formLabelPopoup">
              <label htmlFor="Phone">Phone number</label>
            </div>

            <input
              id="Phone"
              className={classNames("input", {
                popupInputMobile: isMobile,
                errorInputNoWidth:
                  addItemRowData.phone_number.length > 1 &&
                  addItemRowData.phone_number.length < 14 &&
                  submitted &&
                  isMobile,
                errorInput:
                  addItemRowData.phone_number.length > 1 &&
                  addItemRowData.phone_number.length < 14 &&
                  submitted &&
                  !isMobile,
                input: !isMobile,
              })}
              maxLength={14}
              value={phoneFormat(addItemRowData.phone_number)}
              pattern="/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/"
              onChange={(e) => UpdateFormData({ phone_number: e.target.value })}
              aria-describedby="Phone_error"
            />
            {addItemRowData.phone_number.length > 1 &&
            addItemRowData.phone_number.length < 14 &&
            submitted ? (
              <div className="errorText" id="Phone_error">
                Error: You must enter a 10-digit phone number
              </div>
            ) : null}
            <div className="formLabelPopoup" style={{ marginBottom: "10px" }}>
              <FormControlLabel
                checked={addItemRowData.pge_employee === "Yes"}
                control={<Checkbox />}
                onChange={(e) => handleCheckBox(e)}
                label="PG&E employee"
              />
            </div>

            <div style={{ marginTop: "10px" }}>
              <button
                className={classNames(
                  { popupInputMobileItem: isMobile },
                  "yellowButton"
                )}
                onClick={handleSubmit}
              >
                Submit
              </button>
            </div>
            <div className="secondButtonDiv">
              <button onClick={handleClose} className="secondButton">
                Cancel
              </button>
            </div>
          </DialogContent>
        </Box>
      </Dialog>
    </div>
  );
};
