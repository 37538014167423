import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import useCheckScreenSize from "../../../utils/checkScreenSize";
import { Box } from "@mui/material";
import { ItemList } from "../../../types/user-data";
import "../../styles.css";
import classNames from "classnames";
import { useState } from "react";
import { INITIAL_DATA_ADD_ITEM } from "../../../configs/initial-data";
import { BootstrapDialogTitle } from "../../../utils/common-function";

export interface Props {
  addItemPopupFields: (fields: Partial<ItemList>) => void;
  ItemListData: ItemList[];
}

export const AddItemPopup: React.FC<Props> = ({
  addItemPopupFields,
  ItemListData,
}) => {
  const [open, setOpen] = React.useState(false);
  const [addItemRowData, SetAddItemRowData] = useState(INITIAL_DATA_ADD_ITEM);
  const [submitted, setSubmitted] = useState(false);
  const isMobile = useCheckScreenSize({ breakpoint: 850 });

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    SetAddItemRowData(INITIAL_DATA_ADD_ITEM);
  };

  const addFormData = (fields: any) => {
    SetAddItemRowData((prev: any) => {
      return { ...prev, ...fields };
    });
  };
  const handleSubmitPopup = (addItemRowData: any) => {
    setOpen(false);
    addItemPopupFields(addItemRowData);
    SetAddItemRowData(INITIAL_DATA_ADD_ITEM);
  };
  const handleSubmit = () => {
    setSubmitted(true);
    if (
      addItemRowData.amount &&
      addItemRowData.description &&
      !(parseFloat(addItemRowData.amount!) > 10000000) &&
      !(addItemRowData.amount?.toString().split(".")[1]?.length! > 2)
    ) {
      handleSubmitPopup(addItemRowData);
      setSubmitted(false);
    }
  };
  return (
    <div>
      <input
        type="button"
        onClick={handleClickOpen}
        className={classNames({
          disableButton: ItemListData.length >= 10,
          blueButton: ItemListData.length < 10,
        })}
        value="Add item"
        disabled={ItemListData.length >= 10}
      />
      <Dialog
        maxWidth={"md"}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            m: "auto",
            width: "fit-content",
          }}
          className={classNames({ popup: !isMobile })}
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
          >
            <h2 className="subheader">Add item</h2>

            <div>*required field</div>
          </BootstrapDialogTitle>

          <DialogContent>
            <div className="formLabelPopoup">
              <label htmlFor="Make">Make/brand</label>
            </div>
            <input
              id="Make"
              maxLength={20}
              className={classNames(
                { popupInputMobileItem: isMobile },
                "input"
              )}
              value={addItemRowData.make}
              onChange={(e) =>
                addFormData({
                  make: e.target.value
                    .trimStart()
                    .replace(/[-~`!^;|\\":<>?()@$*{}+=]/gi, ""),
                })
              }
            />
            <div className="formLabelPopoup">
              <label htmlFor="Model">Model</label>
            </div>
            <input
              id="Model"
              maxLength={20}
              className={classNames(
                { popupInputMobileItem: isMobile },
                "input"
              )}
              value={addItemRowData.model}
              onChange={(e) =>
                addFormData({
                  model: e.target.value
                    .trimStart()
                    .replace(/[-~`!^;|\\":<>?()@$*{}+=]/gi, ""),
                })
              }
            />
            <div className={classNames({ row: !isMobile })}>
              <div className={classNames({ twoFields: !isMobile })}>
                <div className="formLabelPopoup">
                  <label htmlFor="Year">Year</label>
                </div>
                <input
                  id="Year"
                  className={classNames(
                    {
                      popupInputHalf: !isMobile,
                      popupInputMobileItem: isMobile,
                    },
                    "input"
                  )}
                  maxLength={20}
                  value={addItemRowData.age}
                  onChange={(e) => addFormData({ age: e.target.value })}
                />
              </div>
              <div>
                <div className="formLabelPopoup">
                  <label htmlFor="Amount">*Amount</label>
                </div>
                <div className="input-icon">
                  <input
                    className={classNames(
                      "adornedInputPopup",
                      !addItemRowData.amount && submitted && isMobile
                        ? "errorpopupInputMobileItem"
                        : "inputpopupInputMobileItem",
                      !addItemRowData.amount && submitted && !isMobile
                        ? "errorpopupInputHalf"
                        : "popupInputHalf",
                      parseFloat(addItemRowData.amount!) > 10000000 ||
                        addItemRowData.amount?.toString().split(".")[1]
                          ?.length! > 2
                        ? "errorpopupInputHalf"
                        : "popupInputHalf"
                    )}
                    id="Amount"
                    maxLength={11}
                    onChange={(e) => {
                      addFormData({
                        amount: e.target.value.replace(/[^0-9.]/gi, ""),
                      });
                    }}
                    value={addItemRowData.amount}
                    aria-describedby="Amount_error"
                  />
                  <i>$</i>
                </div>

                {!addItemRowData.amount && submitted ? (
                  <div className="errorText" id="Amount_error">
                    Error: Amount is required
                  </div>
                ) : null}
                {(parseFloat(addItemRowData.amount!) > 10000000 ||
                  addItemRowData.amount?.toString().split(".")[1]?.length! >
                    2) && (
                  <div
                    className="errorText"
                    id="Amount_error"
                    style={{ width: "150px" }}
                  >
                    Error: Invalid amount entry. Please enter value between 0.00
                    and 10000000.00
                  </div>
                )}
              </div>
            </div>

            <div style={{ marginBottom: "10px" }}>
              <div className="formLabelPopoup">
                <label htmlFor="Description">*Item description</label>
              </div>

              <textarea
                id="Description"
                className={classNames(
                  !addItemRowData.description && submitted
                    ? "errorTextarea"
                    : "textarea",
                  !addItemRowData.description &&
                    submitted &&
                    isMobile &&
                    "popupInputMobileItem",

                  isMobile && "popupInputMobileItem"
                )}
                rows={6}
                cols={37}
                maxLength={100}
                onChange={(e) => {
                  addFormData({
                    description: e.target.value
                      .trimStart()
                      .replace(/[-~`!^;|\\":<>?()@$*{}+=]/gi, ""),
                  });
                }}
                value={addItemRowData.description}
                aria-describedby="Description_error"
              />
              {!addItemRowData.description && submitted ? (
                <div className="errorText" id="Description_error">
                  Error: Item description is required
                </div>
              ) : null}
            </div>
            <div style={{ marginTop: "10px" }}>
              <button
                className={classNames(
                  { popupInputMobileItem: isMobile },
                  "yellowButton"
                )}
                onClick={handleSubmit}
              >
                Submit
              </button>
            </div>
            <div className="secondButtonDiv">
              <button onClick={handleClose} className="secondButton">
                Cancel
              </button>
            </div>
          </DialogContent>
        </Box>
      </Dialog>
    </div>
  );
};
