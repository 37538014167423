import * as React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import classNames from "classnames";
import useCheckScreenSize from "../../utils/checkScreenSize";
import "../../components/styles.css";

export const ClaimsFooter: React.FC = () => {
  const isMobile = useCheckScreenSize({ breakpoint: 800 });
  return (
    <div className="footerContainer">
      <Container maxWidth="lg">
        <div
          className={classNames(!isMobile && "row footerText", "footerLinks")}
        >
          <div className="footerLinksPadding">
            <a
              className="secondButton"
              href="https://www.pge.com/en_US/about-pge/company-information/diversity-and-inclusion/accessibility/accessibility.page"
            >
              Accessibility
            </a>
          </div>
          <div className="footerLinksPadding">
            <a
              className="secondButton"
              href="https://www.pge.com/en_US/about-pge/company-information/disclosure/disclosure.page?"
            >
              Terms & Conditions
            </a>
          </div>
          <div className="footerLinksPadding">
            <a
              className="secondButton"
              href="https://www.pge.com/en_US/about-pge/company-information/regulation/regulation.page"
            >
              Regulation
            </a>
          </div>
          <div className="footerLinksPadding">
            <a
              className="secondButton"
              href="https://www.pge.com/en_US/about-pge/company-information/privacy-policy/privacy.page"
            >
              Privacy
            </a>
          </div>
          <div className="footerLinksPadding">
            <a
              className="secondButton"
              href="https://www.pge.com/en_US/about-pge/company-information/security/security.page"
            >
              Security
            </a>
          </div>
          <div className="footerLinksPadding">
            <button
              className="secondButton ot-sdk-show-settings"
              id="cookie_settings ot-sdk-btn"
            >
              Do Not Sell My Personal Information
            </button>
          </div>
          <div className="footerLinksPadding">
            <a
              className="secondButton"
              href="https://www.pge.com/en_US/sitemap.page?"
            >
              Site Map
            </a>
          </div>
        </div>
        <Typography variant="caption" color="initial" className="mobileBar">
          ©{new Date().getFullYear()} Pacific Gas and Electric Company
        </Typography>
      </Container>
    </div>
  );
};
