import * as React from "react";
import AppBar from "@mui/material/AppBar";

import Toolbar from "@mui/material/Toolbar";

import Container from "@mui/material/Container";

import { ReactComponent as PGEIcon } from "../../Assets/PGElogo.svg";

export const ClaimsHeader: React.FC = () => {
  return (
    <AppBar
      position="static"
      style={{
        backgroundColor: "white",
        paddingTop: "30px",
        paddingBottom: "5px",
      }}
      className="mobileBar"
    >
      <Container maxWidth="lg">
        <Toolbar disableGutters>
          <a href="https://www.pge.com/" aria-label="PG&E Home">
            <PGEIcon />
          </a>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default ClaimsHeader;
