import { Dialog } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import IdleTimer from "react-idle-timer";
import { INITIAL_DATA } from "../configs/initial-data";
import { UserData } from "../types/user-data";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as TimeoutAlertIcon } from "../Assets/SessionAlert.svg";
import "../components/styles.css";

interface Props extends UserData {
  updateFields: (fields: Partial<UserData>) => void;
  data: Partial<UserData>;
  setItemList: (fields: any) => void;
  setWitnessListData: (fields: any) => void;
}

export const IdleTimerContainer: React.FC<Props> = ({
  data,
  updateFields,
  setItemList,
  setWitnessListData,
}) => {
  const idleTimerRef = useRef(null);
  const [isActive, setIsActive] = useState(true);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalIsOpen5min, setModalIsOpen5min] = useState(false);
  const [modalIsClose, setModalIsClose] = useState(false);
  const sessionTimeoutRef = useRef<any>(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    window.addEventListener("beforeunload", redirectHome);
    return () => {
      window.removeEventListener("beforeunload", redirectHome);
    };
  });
  useEffect(() => {
    if (
      location.state === undefined ||
      location.state == null ||
      location.state === ""
    ) {
      navigate("/");
    }
  }, []);

  const redirectHome = (e: any) => {
    navigate("/");
  };

  const onIdle = () => {
    setModalIsOpen(true);

    //10 minute = 600000
    // 5 min = 300000
    sessionTimeoutRef.current = setTimeout(onExit15Min, 600000);
  };
  const onExit15Min = () => {
    setModalIsOpen5min(true);

    clearTimeout(sessionTimeoutRef.current);

    sessionTimeoutRef.current = setTimeout(onExit5Min, 300000);
  };
  const onExit5Min = () => {
    setIsActive(false);
    setModalIsClose(true);
    clearTimeout(sessionTimeoutRef.current);
  };

  const onClickExit = () => {
    setModalIsOpen(false);
    setModalIsOpen5min(false);
    clearTimeout(sessionTimeoutRef.current);

    updateFields(INITIAL_DATA);
    setItemList([]);
    setWitnessListData([]);
    setIsActive(true);
    navigate("/");
  };

  const Continue = () => {
    setModalIsOpen(false);
    setModalIsOpen5min(false);
    if (isActive === false) {
      updateFields(INITIAL_DATA);
      setWitnessListData([]);
      setItemList([]);
      setIsActive(true);
      navigate("/");
    }
    clearTimeout(sessionTimeoutRef.current);
  };
  return (
    <div>
      {isActive && modalIsOpen ? (
        <Dialog
          maxWidth="xs"
          sx={{ marginLeft: "24px" }}
          open={modalIsOpen} //modalIsOpen
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div
            id="alert-dialog-title"
            className="subheader sessionTimeoutAlert"
          >
            <div style={{ marginBottom: "10px" }}>
              <TimeoutAlertIcon />
            </div>
            Your session will expire in <b>15 minutes</b>.
          </div>
          <div
            id="alert-dialog-description"
            className="sessionTimeoutDescription"
          >
            For security reasons, your data will be deleted when the session
            expires.
          </div>
          <div className="sessionTimeoutButtons">
            <button
              onClick={Continue}
              className="yellowButton"
              style={{ marginRight: "24px", width: "full" }}
            >
              Stay connected
            </button>
            <div className="secondButtonDiv" style={{ marginTop: "70px" }}>
              <u className="secondButton" onClick={onClickExit}>
                End Session
              </u>
            </div>
          </div>
          <br />
        </Dialog>
      ) : null}

      {isActive && modalIsOpen5min ? (
        <Dialog
          maxWidth="xs"
          sx={{ marginLeft: "24px" }}
          open={modalIsOpen5min} //modalIsOpen
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div
            id="alert-dialog-title"
            className="subheader sessionTimeoutAlert"
          >
            <div style={{ marginBottom: "10px" }}>
              <TimeoutAlertIcon />
            </div>
            Your session will expire in <b>5 minutes</b>.
          </div>
          <div
            id="alert-dialog-description"
            className="sessionTimeoutDescription"
          >
            For security reasons, your data will be deleted when the session
            expires.
          </div>
          <div className="sessionTimeoutButtons">
            <button
              onClick={Continue}
              className="yellowButton"
              style={{ marginRight: "24px", width: "full" }}
            >
              Stay connected
            </button>
            <div className="secondButtonDiv" style={{ marginTop: "70px" }}>
              <u className="secondButton" onClick={onClickExit}>
                End Session
              </u>
            </div>
          </div>
          <br />
        </Dialog>
      ) : null}

      {!isActive && modalIsClose ? (
        <Dialog
          maxWidth="xs"
          open={modalIsOpen} //modalIsOpen
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div
            id="alert-dialog-title"
            className="subheader sessionTimeoutAlert"
          >
            <div style={{ marginBottom: "10px" }}>
              <TimeoutAlertIcon />
            </div>
            Your <b>session has expired</b> due to inactivity.
          </div>

          <div id="alert-dialog-description" className="sessionTimeoutDeleted">
            For security reasons, your data was deleted.
          </div>

          <div className="sessionTimeoutOk">
            <button
              onClick={Continue}
              className="yellowButton sessionTimeoutOkButton"
            >
              OK
            </button>

            <br />
          </div>
        </Dialog>
      ) : null}

      {/* 15 minute = 900000 milliseconds */}
      <IdleTimer ref={idleTimerRef} timeout={900000} onIdle={onIdle} />
    </div>
  );
};
