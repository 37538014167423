import {
  Grid,
  Box,
  Table,
  TableContainer,
  Paper,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  tableCellClasses,
} from "@mui/material";
import { useState, useEffect } from "react";
import { UserData, WitnessList } from "../../../types/user-data";
import { headCells, StyledTableRow } from "../../../utils/common-function";
import "../../styles.css";
import { AddWitnessPopup } from "./AddWitnessPopup";
import { DeleteWitnessPopup } from "./DeleteWitnessPopup";
import { UpdateWitnessPopup } from "./UpdateWitnessPopup";

interface Props extends UserData {
  witnessListData: WitnessList[];
  setWitnessListData: (fields: any) => void;
  setShowAlert: (value: boolean) => void;
}

interface EnhancedTableProps {
  rowCount: number;
}
function EnhancedTableHead(props: EnhancedTableProps) {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            style={{
              fontWeight: 700,
              color: "white",
              backgroundColor: "#0C7DBB",
              paddingLeft: "20px",
            }}
            key={headCell.id}
            align={"left"}
            padding={headCell.disablePadding ? "none" : "normal"}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export const TableWitnessView: React.FC<Props> = ({
  witnessListData,
  setWitnessListData,
  setShowAlert,
}) => {
  const [witnessPopupData, setWitnessPopupData] = useState({});

  useEffect(() => {
    if (Object.keys(witnessPopupData).length > 0) {
      setWitnessListData((prev: any) => {
        return [...prev, witnessPopupData];
      });
    }
  }, [setWitnessListData, witnessPopupData]);

  const handleDelete = (index: any) => {
    const tempArray = [...witnessListData];

    tempArray.splice(index, 1);

    setWitnessListData(tempArray);
  };

  return (
    <>
      <Box sx={{ width: "100%" }}>
        {witnessListData.length > 0 ? (
          <>
            <Paper sx={{ width: "100%", mb: 2 }}>
              <TableContainer sx={{ maxHeight: 400 }}>
                <Table
                  stickyHeader
                  sx={{
                    [`& .${tableCellClasses.root}`]: {
                      borderBottom: "none",
                    },
                  }}
                >
                  <EnhancedTableHead rowCount={witnessListData.length} />
                  <TableBody>
                    {witnessListData.map((row, index) => {
                      return (
                        <StyledTableRow key={index}>
                          <TableCell component="th" scope="row">
                            {row.first_name}
                          </TableCell>
                          <TableCell align="left">{row.last_name}</TableCell>
                          <TableCell align="left">
                            {row.address_line1}
                          </TableCell>

                          <TableCell align="left">{row.city}</TableCell>
                          <TableCell align="left">{row.state}</TableCell>
                          <TableCell align="left">{row.zip_code}</TableCell>
                          <TableCell align="left">{row.phone_number}</TableCell>
                          <TableCell align="left">{row.pge_employee}</TableCell>
                          <TableCell>
                            <div className="row">
                              <UpdateWitnessPopup
                                {...witnessPopupData}
                                witnessListData={witnessListData}
                                index={index}
                                row={row}
                                setWitnessListData={setWitnessListData}
                              />

                              <DeleteWitnessPopup
                                index={index}
                                handleDelete={handleDelete}
                                {...witnessPopupData}
                                setShowAlert={setShowAlert}
                              />
                            </div>
                          </TableCell>
                        </StyledTableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div>
                <AddWitnessPopup
                  {...witnessPopupData}
                  witnessLength={witnessListData.length}
                  setWitnessPopupData={setWitnessPopupData}
                  setShowAlert={setShowAlert}
                />
              </div>
            </Grid>
          </>
        ) : (
          <div>
            <AddWitnessPopup
              {...witnessPopupData}
              witnessLength={witnessListData.length}
              setWitnessPopupData={setWitnessPopupData}
              setShowAlert={setShowAlert}
            />
          </div>
        )}
      </Box>
    </>
  );
};

export const MobileWitnessTableView: React.FC<Props> = ({
  witnessListData,
  setWitnessListData,
  setShowAlert,
}) => {
  const [witnessPopupData, setWitnessPopupData] = useState({});

  useEffect(() => {
    if (Object.keys(witnessPopupData).length > 0) {
      setWitnessListData((prev: any) => {
        return [...prev, witnessPopupData];
      });
    }
  }, [setWitnessListData, witnessPopupData]);

  const handleDelete = (index: any) => {
    const temp = [...witnessListData];

    temp.splice(index, 1);

    setWitnessListData(temp);
  };

  return (
    <>
      {witnessListData.length > 0 ? (
        <>
          <TableContainer
            sx={{ maxWidth: 380, maxHeight: 400 }}
            component={Paper}
          >
            <Table
              stickyHeader
              sx={{
                [`& .${tableCellClasses.root}`]: {
                  borderBottom: "none",
                },
                maxWidth: 380,
              }}
              aria-label="simple table"
            >
              <TableBody>
                {witnessListData?.map((row, index) => {
                  return (
                    <>
                      <TableRow>
                        <TableCell
                          sx={{ paddingLeft: "0px" }}
                          style={{
                            color: "white",
                            backgroundColor: "#0C7DBB",
                          }}
                        >
                          <div className="row">
                            <div style={{ marginLeft: "18px" }}>
                              <div style={{ fontWeight: "700" }}>
                                *First name
                              </div>
                              <div>{row.first_name}</div>
                            </div>
                          </div>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th">
                          <div style={{ fontWeight: "700" }}>*Last name</div>
                          <div>{row.last_name}</div>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          component="th"
                          style={{
                            backgroundColor: "#F4F5F6",
                          }}
                        >
                          <div style={{ fontWeight: "700" }}>Address</div>
                          <div>{row.address_line1}</div>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th">
                          <div style={{ fontWeight: "700" }}>City</div>
                          <div>{row.city}</div>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          component="th"
                          style={{
                            backgroundColor: "#F4F5F6",
                          }}
                        >
                          <div style={{ fontWeight: "700" }}>State</div>
                          <div>{row.state}</div>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th">
                          <div style={{ fontWeight: "700" }}>ZIP code</div>
                          <div>{row.zip_code}</div>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          component="th"
                          style={{
                            backgroundColor: "#F4F5F6",
                          }}
                        >
                          <div style={{ fontWeight: "700" }}>Phone number</div>
                          <div>{row.phone_number}</div>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th">
                          <div style={{ fontWeight: "700" }}>
                            PG&E employee?
                          </div>
                          <div>{row.pge_employee}</div>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          component="th"
                          style={{
                            backgroundColor: "#F4F5F6",
                          }}
                        >
                          <div style={{ fontWeight: "700" }}>Action</div>
                          <div className="row">
                            <UpdateWitnessPopup
                              {...witnessPopupData}
                              witnessListData={witnessListData}
                              index={index}
                              row={row}
                              setWitnessListData={setWitnessListData}
                            />
                            <DeleteWitnessPopup
                              index={index}
                              handleDelete={handleDelete}
                              {...witnessPopupData}
                              setShowAlert={setShowAlert}
                            />{" "}
                          </div>
                        </TableCell>
                      </TableRow>
                    </>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <div style={{ marginTop: "20px" }}>
            <AddWitnessPopup
              {...witnessPopupData}
              witnessLength={witnessListData.length}
              setWitnessPopupData={setWitnessPopupData}
              setShowAlert={setShowAlert}
            />
          </div>
        </>
      ) : (
        <div>
          <AddWitnessPopup
            {...witnessPopupData}
            witnessLength={witnessListData.length}
            setWitnessPopupData={setWitnessPopupData}
            setShowAlert={setShowAlert}
          />
        </div>
      )}
    </>
  );
};
