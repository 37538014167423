import { FormControlLabel, Radio, RadioGroup, TextField } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { ReactComponent as CalendarIcon } from "../../../Assets/Calendar.svg";
import classNames from "classnames";
import React, { useState, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { stateConfig } from "../../../configs/state";
import { UserData } from "../../../types/user-data";
import useCheckScreenSize from "../../../utils/checkScreenSize";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";

import "../../styles.css";
import { InfoBanner } from "../../../utils/InfoBanner";
import moment from "moment";

interface Props extends UserData {
  updateFields: (fields: Partial<UserData>) => void;
  data: Partial<UserData>;
}

export const IncidentInfo: React.FC<Props> = ({ updateFields, data }) => {
  const {
    register,
    resetField,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
    reValidateMode: "onChange",
  });
  const [submitted, setSubmitted] = useState(false);
  const navigate = useNavigate();
  const isMobile = useCheckScreenSize({ breakpoint: 850 });
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [dateValue, setDateValue] = useState<Dayjs | null>(null);

  const handleNext = () => {
    setSubmitted(true);
    if (
      data.incident_date &&
      data.incident_time &&
      !isInTheFuture(new Date(data?.incident_date!)) &&
      !isInThePast(new Date(data?.incident_date!)) &&
      dateValue !== null &&
      /^\d{1,2}\/\d{1,2}\/\d{4}$/.test(data.incident_date as string)
    ) {
      navigate("../ClaimDetails");
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  function DateIcon(props: any) {
    return (
      <span style={{ marginRight: "5px", marginLeft: "5px" }}>
        <CalendarIcon {...props} />
      </span>
    );
  }
  const submit = () => {
    setSubmitted(true);
  };
  const handleBack = () => {
    navigate("../");
  };
  function isInTheFuture(date: string | Date) {
    const today = new Date();

    return date > today;
  }
 function isInThePast(date: string | Date) {
    const pastDate = new Date("2000-01-01");

    return date < pastDate;
  }

  useEffect(() => {
    if (dateValue === null && data.incident_date) {
      setDateValue(dayjs(data.incident_date));
    }
    if (isInTheFuture(new Date(data?.incident_date!))) {
      setDateValue(null);
    }
    if (isInThePast(new Date(data?.incident_date!))) {
      setDateValue(null);
    }
  }, []);

  function resetFields() {
    resetField("incident_address_line1");
    data.incident_address_line1 = "";
    resetField("incident_address_line2");
    data.incident_address_line2 = "";
    resetField("incident_city");
    data.incident_city = "";
    resetField("incident_zip_code");
    data.incident_zip_code = "";
    resetField("incident_description");
    data.incident_description = "";
  }

  return (
    <>
      <form onSubmit={handleSubmit(handleNext)}>
        <div>
          <h2 className="subheader">Incident information</h2>
          <div style={{ marginBottom: "20px" }}>*required field </div>
          <div className="formLabel">
            <label htmlFor="estimatedDateofIncident">
              *Estimated date of incident
            </label>
          </div>
          <div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Controller
                name="date"
                control={control}
                render={({ field, ...props }) => {
                  return (
                    <DesktopDatePicker
                      open={calendarOpen}
                      onOpen={() => setCalendarOpen(true)}
                      onClose={() => setCalendarOpen(false)}
                      inputFormat="MM/DD/YYYY"
                      value={dateValue}
                      onChange={(value) => {
                        setDateValue(value);
                        updateFields({
                          incident_date: moment(value?.format())
                            .utc()
                            .format("MM/DD/YYYY"),
                        });
                      }}
                      components={{
                        OpenPickerIcon: DateIcon,
                      }}
                      componentsProps={{
                        actionBar: {
                          actions: ["today"],
                        },
                      }} 
                      disableFuture
                      renderInput={(params) => (
                        <TextField
                          fullWidth
                          id="estimatedDateofIncident"
                          aria-labelledby="estimatedDateofIncident estimatedDateofIncident_error"
                          sx={
                            (!data.incident_date && submitted) ||
                            (dateValue === null && submitted) ||
                            (data.incident_date &&
                              !/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(
                                data.incident_date as string
                              )) ||
                            isInTheFuture(new Date(data?.incident_date!))||
                            isInThePast(new Date(data?.incident_date!))
                              ? {
                                  "& .MuiOutlinedInput-root": {
                                    "& fieldset": {
                                      borderColor: "#e2231a",
                                      border: "2.5px solid #e2231a",
                                    },
                                    "&:hover fieldset": {
                                      borderColor: "#e2231a",
                                    },
                                    "&.Mui-focused fieldset": {
                                      borderColor: "#e2231a",
                                    },
                                    width: "344px",
                                    height: "2.7em",
                                  },
                                }
                              : {
                                  "& .MuiOutlinedInput-root": {
                                    "& fieldset": {
                                      borderColor: "#4f4f4f",
                                    },
                                    "&.Mui-focused fieldset": {
                                      borderColor: "#0c7dbb",
                                    },
                                    width: "344px",
                                    height: "2.7em",
                                  },
                                }
                          }
                          {...params}
                        />
                      )}
                    />
                  );
                }}
              />
            </LocalizationProvider>
          </div>
          {(!data.incident_date && submitted) ||
          (dateValue === null && submitted) ? (
            <div className="errorText" id="estimatedDateofIncident_error">
              Error: Please enter a valid date
            </div>
          ) : null}

          {data.incident_date &&
          dateValue !== null &&
          !/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(data.incident_date as string) ? (
            <div className="errorText" id="estimatedDateofIncident_error">
              Error: Please enter a valid date in MM/DD/YYYY format
            </div>
          ) : null}
          {data.incident_date &&
          dateValue !== null &&
          isInTheFuture(new Date(data?.incident_date!)) ? (
            <div className="errorText" id="estimatedDateofIncident_error">
              Error: Future date is not allowed
            </div>
          ) : null}
            {data.incident_date &&
          dateValue !== null &&
          isInThePast(new Date(data?.incident_date!)) ? (
            <div className="errorText" id="estimatedDateofIncident_error">
              Error: Year should be in current century
            </div>
          ) : null}

          <div className="formLabel">
            <label htmlFor="estimatedTimeofIncident">
              *Estimated time of incident
            </label>
          </div>

          <input
            id="estimatedTimeofIncident"
            className={classNames({
              errorInput: errors.incident_time,
              input: !errors.incident_time,
            })}
            {...register("incident_time", {
              required: true,
              onChange: (e) => {
                updateFields({ incident_time: e.target.value });
              },
              validate: (value) => value !== "",
            })}
            type="time"
            value={data.incident_time}
            aria-describedby="estimatedTimeofIncident_error"
          />
          {errors.incident_time ? (
            <div className="errorText" id="estimatedTimeofIncident_error">
              Error: Please enter a valid time in 12-hour format
            </div>
          ) : null}

          <div className="formLabel">
            <label id="sameasMailingAddress">*Same as mailing address?</label>
          </div>
          <RadioGroup
            style={{ marginTop: "5px", marginBottom: "10px" }}
            role="radiogroup"
            aria-labelledby="sameasMailingAddress"
            aria-describedby="sameasMailingAddress_error"
          >
            <FormControlLabel
              sx={{ height: "28px" }}
              {...register("same_as_mailing_address", {
                required: true,
                onChange: (e) => {
                  resetFields();
                  updateFields({ same_as_mailing_address: e.target.value });
                },
                validate: (value) => value === "No" || value === "Yes",
              })}
              value="Yes"
              checked={data.same_as_mailing_address === "Yes"}
              control={
                <Radio
                  sx={errors.same_as_mailing_address && { color: "#e2231a" }}
                />
              }
              label="Yes"
              id="sameasMailingAddress_error"
            />
            <FormControlLabel
              {...register("same_as_mailing_address", {
                required: true,
                onChange: (e) => {
                  resetFields();
                  updateFields({ same_as_mailing_address: e.target.value });
                },
                validate: (value) => value === "No" || value === "Yes",
              })}
              value="No"
              checked={data.same_as_mailing_address === "No"}
              control={
                <Radio
                  sx={errors.same_as_mailing_address && { color: "#e2231a" }}
                />
              }
              label="No"
              id="sameasMailingAddress_error"
            />
          </RadioGroup>
          {errors.same_as_mailing_address ? (
            <div className="errorText" id="sameasMailingAddress_error">
              Error: Are you currently employed by PG&E? If not, select No above
            </div>
          ) : null}

          {data.same_as_mailing_address === "No" ? (
            <div>
              <div className="formLabel">
                <label id="location">*Location</label>
              </div>
              <RadioGroup
                style={{ marginTop: "5px", marginBottom: "10px" }}
                role="radiogroup"
                aria-labelledby="location"
                aria-describedby="location_error"
              >
                <FormControlLabel
                  sx={{ height: "28px" }}
                  {...register("incident_location", {
                    required: true,
                    onChange: (e) => {
                      resetFields();
                      updateFields({ incident_location: e.target.value });
                    },
                    validate: (value) =>
                      value === "StreetAddress" ||
                      value === "OtherProvideLocationDescription",
                  })}
                  checked={data.incident_location === "StreetAddress"}
                  value="StreetAddress"
                  control={
                    <Radio
                      sx={errors.incident_location && { color: "#e2231a" }}
                    />
                  }
                  label="Street address"
                  id="location_error"
                />
                <FormControlLabel
                  {...register("incident_location", {
                    required: true,
                    onChange: (e) => {
                      resetFields();
                      updateFields({ incident_location: e.target.value });
                    },
                    validate: (value) =>
                      value === "StreetAddress" ||
                      value === "OtherProvideLocationDescription",
                  })}
                  checked={
                    data.incident_location === "OtherProvideLocationDescription"
                  }
                  value="OtherProvideLocationDescription"
                  control={
                    <Radio
                      sx={errors.incident_location && { color: "#e2231a" }}
                    />
                  }
                  label="Other/Describe"
                  id="location_error"
                />
              </RadioGroup>
              {errors.incident_location ? (
                <div className="errorText" id="location_error">
                  Error: One selection is required
                </div>
              ) : null}
            </div>
          ) : null}

          {data.incident_location === "StreetAddress" &&
          data.same_as_mailing_address === "No" ? (
            <>
              <div className={classNames({ row: !isMobile })}>
                <div className="column" style={{ marginRight: "2%" }}>
                  <div className="formLabel">
                    <label htmlFor="incidentAddress">*Incident address</label>
                  </div>
                  <input
                    id="incidentAddress"
                    className={classNames(
                      errors.incident_address_line1 && !isMobile
                        ? "errorInputMail"
                        : "inputMail",
                      errors.incident_address_line1 && isMobile
                        ? "errorInputMailNoWidth"
                        : "inputMail",
                      isMobile ? "inputMailMobile" : "input"
                    )}
                    {...register("incident_address_line1", {
                      required: true,
                      onChange: (e) =>
                        updateFields({
                          incident_address_line1: e.target.value
                            .trimStart()
                            .replace(/[-~`!^;|\\":<>?()@$*{}+=]/gi, ""),
                        }),
                    })}
                    value={data.incident_address_line1}
                    maxLength={100}
                    aria-describedby="incidentAddress_error"
                  />
                  {errors.incident_address_line1 ? (
                    <div className="errorText" id="incidentAddress_error">
                      Error: Incident address is required
                    </div>
                  ) : null}
                </div>
                <div className="column" style={{ marginRight: "2%" }}>
                  <div className="formLabel">
                    <label htmlFor="apt">Apt/Suite</label>
                  </div>
                  <input
                    id="apt"
                    className={classNames(
                      errors.address_line2 ? "errorInputSmall" : "inputSmall",
                      isMobile ? "input" : "inputSmall"
                    )}
                    onChange={(e) =>
                      updateFields({
                        incident_address_line2: e.target.value
                          .trimStart()
                          .replace(/[-~`!^;|\\":<>?()@$*{}+=]/gi, ""),
                      })
                    }
                    value={data.incident_address_line2}
                  />
                </div>
              </div>
              <div className={classNames({ row: !isMobile })}>
                <div className="column" style={{ marginRight: "2%" }}>
                  <div className="formLabel">
                    <label htmlFor="city">*City </label>
                  </div>
                  <input
                    id="city"
                    className={classNames({
                      errorInputCity: errors.incident_city,
                      inputCity: !errors.incident_city,
                    })}
                    {...register("incident_city", {
                      required: true,
                      onChange: (e) =>
                        updateFields({
                          incident_city: e.target.value
                            .trimStart()
                            .replace(/[-~`!^;|\\":<>?()@$*{}+=]/gi, ""),
                        }),
                    })}
                    value={data.incident_city}
                    maxLength={50}
                    aria-describedby="city_error"
                  />
                  {errors.incident_city ? (
                    <div className="errorText" id="city_error">
                      Error: City is required
                    </div>
                  ) : null}
                </div>
                <div className="column" style={{ marginRight: "2%" }}>
                  <div className="formLabel">
                    <label htmlFor="zipCode">*ZIP code</label>
                  </div>
                  <input
                    id="zipCode"
                    maxLength={5}
                    className={classNames({
                      errorInputSmall: errors.incident_zip_code,
                      inputSmall: !errors.incident_zip_code,
                    })}
                    {...register("incident_zip_code", {
                      required: true,
                      validate: { validateNumber: (v) => v.length === 5 },
                      onChange: (e) =>
                        updateFields({
                          incident_zip_code: e.target.value
                            .trim()
                            .replace(/[^0-9 ]/gi, ""),
                        }),
                    })}
                    value={data.incident_zip_code}
                    aria-describedby="zip_code_error"
                  />
                  {errors.incident_zip_code ? (
                    <div
                      className={classNames("errorText", {
                        absolute: !isMobile,
                      })}
                      id="zip_code_error"
                    >
                      Error: Enter ZIP code
                    </div>
                  ) : null}
                </div>
                <div className="column" style={{ marginRight: "3%" }}>
                  <div className="formLabel">
                    <label htmlFor="stateView">*State</label>
                  </div>
                  <select
                    className="inputSmallState"
                    id="state"
                    defaultValue={data.incident_state}
                    value={data.incident_state}
                    onChange={(e) =>
                      updateFields({ incident_state: e.target.value })
                    }
                  >
                    {stateConfig.map((state, index) => (
                      <option key={index} value={state.key}>
                        {" "}
                        {state.key}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </>
          ) : null}

          {data.incident_location === "OtherProvideLocationDescription" &&
          data.same_as_mailing_address === "No" ? (
            <div>
              <div className="formLabel">
                <label htmlFor="description">*Description of location</label>
              </div>
              <textarea
                id="description"
                className={classNames({
                  errorTextarea: errors.incident_description,
                  textarea: !errors.incident_description,
                })}
                {...register("incident_description", {
                  required: true,
                  onChange: (e) =>
                    updateFields({
                      incident_description: e.target.value
                        .trimStart()
                        .replace(/[-~`!^;|\\":<>?()@$*{}+=]/gi, ""),
                    }),
                })}
                maxLength={300}
                rows={6}
                cols={42}
                value={data.incident_description}
                aria-describedby="descriptionlocation_error"
              />
              <br />

              <div>
                You have <b>{300 - data.incident_description!.length}</b>{" "}
                characters remaining
              </div>

              {errors.incident_description ? (
                <div className="errorText" id="descriptionlocation_error">
                  Error: Location description is required
                </div>
              ) : null}
              <div className={classNames({ row: !isMobile })}>
                <div className="column" style={{ marginRight: "2%" }}>
                  <div className="formLabel">
                    <label htmlFor="city1">*City</label>
                  </div>

                  <input
                    id="city1"
                    className={classNames(
                      errors.incident_city ? "errorInputCity" : "inputCity",
                      isMobile ? "input" : "inputCity"
                    )}
                    {...register("incident_city", {
                      required: true,
                      onChange: (e) =>
                        updateFields({
                          incident_city: e.target.value
                            .trimStart()
                            .replace(/[-~`!^;|\\":<>?()@$*{}+=]/gi, ""),
                        }),
                    })}
                    value={data.incident_city}
                    aria-describedby="city1_error"
                  />
                  {errors.incident_city ? (
                    <div className="errorText" id="city1_error">
                      Error: City is required
                    </div>
                  ) : null}
                </div>
                <div className="column" style={{ marginRight: "3%" }}>
                  <div className="formLabel">
                    <label htmlFor="state">*State</label>
                  </div>
                  <select
                    className="inputSmallState"
                    id="state"
                    defaultValue={data.incident_state}
                    value={data.incident_state}
                    onChange={(e) =>
                      updateFields({ incident_state: e.target.value })
                    }
                  >
                    {stateConfig.map((state, index) => (
                      <option key={index} value={state.key}>
                        {" "}
                        {state.key}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          ) : null}
        </div>
        <br />
        <br />

        <button className="yellowButton" type="submit" onClick={submit}>
          Next
        </button>
        <div className="secondButtonDiv">
          <button onClick={handleBack} className="secondButton">
            Back
          </button>
        </div>
      </form>
      <InfoBanner />
    </>
  );
};
