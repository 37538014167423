import React, { useState, useEffect } from "react";

import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { Claimant } from "../Forms/Claimant/Claimant";

import { IncidentInfo } from "../Forms/IncidentInfo/IncidentInfo";
import { SupportingDocuments } from "../Forms/SupportingDocuments/SupportingDocuments";
import { Witnesses } from "../Forms/Witnesses/Witnesses";
import { INITIAL_DATA } from "../../configs/initial-data";
import { ItemList, WitnessList, UserData } from "../../types/user-data";
import { ClaimDetails } from "../Forms/ClaimDetails/ClaimDetails";
import { Confirmation } from "../Forms/Confirmation/Confirmation";
import { array } from "../Stepper/Stepper";
import { Layout } from "../Layout";
import { IdleTimerContainer } from "../../utils/IdleTimerContainer";

interface Props {
  setActiveStep: any;
  activeStep: number;
}

export const RouterDom: React.FC<Props> = ({ setActiveStep, activeStep }) => {
  const [data, setData] = useState(INITIAL_DATA);
  const [ItemListData, setItemList] = useState<ItemList[]>([]);
  const [witnessListData, setWitnessListData] = useState<WitnessList[]>([]);
  const [multipleImages, setMultipleImages] = useState<any[]>([]);
  const [claimId, setClaimId] = useState<string>("");
  function updateFields(fields: Partial<UserData>) {
    setData((prev) => {
      return { ...prev, ...fields };
    });
  }
  const stepMap: Record<number, any> = {
    1: <Claimant {...data} data={data} updateFields={updateFields} />,
    2: <IncidentInfo {...data} data={data} updateFields={updateFields} />,
    3: (
      <ClaimDetails
        {...data}
        data={data}
        updateFields={updateFields}
        {...ItemListData}
        setItemList={setItemList}
        ItemListData={ItemListData}
      />
    ),
    4: (
      <Witnesses
        {...data}
        data={data}
        {...witnessListData}
        setWitnessListData={setWitnessListData}
        witnessListData={witnessListData}
      />
    ),
    5: (
      <SupportingDocuments
        {...data}
        data={data}
        {...ItemListData}
        ItemListData={ItemListData}
        {...witnessListData}
        witnessListData={witnessListData}
        updateFields={updateFields}
        setWitnessListData={setWitnessListData}
        setItemList={setItemList}
        setClaimId={setClaimId}
        setMultipleImages={setMultipleImages}
        multipleImages={multipleImages}
      />
    ),
    6: <Confirmation claimId={claimId} />,
  };
  const location = useLocation();
  useEffect(() => {
    const stepNumber = array.find(
      (step: any) => step.path === location.pathname
    )?.displayOrder;
    if (stepNumber) {
      setActiveStep(stepNumber);
    }
  });
  return (
    <>
      <IdleTimerContainer
        {...data}
        data={data}
        updateFields={updateFields}
        {...ItemListData}
        setItemList={setItemList}
        setWitnessListData={setWitnessListData}
      />
      <Routes>
        {array.map((step: any) => {
          return (
            <Route
              key={step.displayOrder}
              path={step.path}
              element={
                <Layout
                  current={step.displayOrder}
                  component={stepMap[step.displayOrder]}
                />
              }
            />
          );
        })}
      </Routes>
    </>
  );
};
