import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import { Stepper } from "./Stepper/Stepper";
import useCheckScreenSize from "../utils/checkScreenSize";
import "./styles.css";

import { utagView, utagLink } from "./utils/tealium.js";
import { getClaimsSteps } from "../types/claims-steps";

export interface Props {
  component: any;
  current: number;
}
export const Layout: React.FC<Props> = ({ component, current }) => {
  const { t } = useTranslation();
  const claimSteps = getClaimsSteps();
  const isLarge = useCheckScreenSize({ breakpoint: 1000 });

  useEffect(() => {
    const buttonName = current === claimSteps.length - 1 ? "submit" : "next";
    const pageTitle = `${t("AppName")} - ${claimSteps[current - 1].labelId}`;
    document.title = pageTitle;
    if (current === 1) {
      utagView({ page_name: pageTitle });
    } else {
      const btnTitle = `${t("AppName")} - ${
        claimSteps[current - 2].labelId
      } - ${buttonName} button`;
      utagLink({
        form_button: btnTitle,
        form_id: "mainform",
      });
      utagView({ page_name: pageTitle });
    }
  }, [current]);

  return (
    <Grid container spacing={4}>
      <Grid item lg={3} md={2}>
        <h1 className="header">PG&E Claims</h1>
        <Stepper activeStepIndex={current} />
      </Grid>
      <Grid item lg={9} md={10} sm={12}>
        <div className="row contentPadding">
          {!isLarge && (
            <div style={{ marginRight: "70px" }} className="divideLine"></div>
          )}
          <div>{component}</div>
        </div>
      </Grid>
    </Grid>
  );
};
