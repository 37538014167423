import {
  Grid,
  Box,
  OutlinedInput,
  InputAdornment,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import classNames from "classnames";
import { useEffect } from "react";
import {
  FieldValues,
  UseFormHandleSubmit,
  UseFormRegister,
} from "react-hook-form";
import { stateConfig } from "../../../configs/state";
import { UserData } from "../../../types/user-data";
import { phoneFormat } from "../../../utils/common-function";

import "../../styles.css";

interface Props extends UserData {
  updateFields: (fields: Partial<UserData>) => void;
  data: Partial<UserData>;
  errors: any;
  resetField?: any;
  register: UseFormRegister<FieldValues>;
  handleSubmit: UseFormHandleSubmit<FieldValues>;
  setItemList: (fields: any) => void;
}
interface BodilyInjuryProps extends UserData {
  updateFields: (fields: Partial<UserData>) => void;
  data: Partial<UserData>;
  errors: any;
  resetField?: any;
  register: UseFormRegister<FieldValues>;
  handleSubmit: UseFormHandleSubmit<FieldValues>;
  bodilyInjurySubTotal: number;
}
export const BodilyInjury: React.FC<BodilyInjuryProps> = ({
  data,
  updateFields,
  bodilyInjurySubTotal,
  register,
  errors,
}) => {
  useEffect(() => {
    if (data?.bodily_injury_lost_wages!.length > 11) {
      data.bodily_injury_lost_wages = "";
    }
    if (data?.bodily_injury_medical_expenses!.length > 11) {
      data.bodily_injury_medical_expenses = "";
    }
    if (data?.bodily_injury_other_losses!.length > 11) {
      data.bodily_injury_other_losses = "";
    }
  }, [
    data,
    data?.bodily_injury_lost_wages,
    data?.bodily_injury_medical_expenses,
    data.bodily_injury_other_losses,
  ]);

  return (
    <>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <h2 className="subheader">Bodily injury</h2>
      </Grid>
      <Box sx={{ maxWidth: "21.875rem" }}>
        <div className="formLabel">
          <label htmlFor="lostWages">Lost wages (if any)</label>
        </div>
        <div className="input-icon">
          <input
            className={classNames(
              "adornedInput",
              parseFloat(data?.bodily_injury_lost_wages!) > 10000000 ||
                data.bodily_injury_lost_wages?.toString().split(".")[1]
                  ?.length! > 2
                ? "errorInput"
                : "input"
            )}
            {...register("bodily_injury_lost_wages", {
              required: false,
              pattern: {
                value: /^\d{0,8}\.?\d{0,2}?$/,
                message:
                  "Invalid amount entry. Please enter value between 0.00 and 10000000.00",
              },
              onChange: (e) => {
                updateFields({
                  bodily_injury_lost_wages: e.target.value.replace(
                    /[^0-9.]/gi,
                    ""
                  ),
                });
              },
            })}
            id="lostWages"
            value={data.bodily_injury_lost_wages}
            aria-describedby="bodily_injury_lost_wages_error"
          />
          <i>$</i>
        </div>

        {(parseFloat(data.bodily_injury_lost_wages!) > 10000000 ||
          data.bodily_injury_lost_wages?.toString().split(".")[1]?.length! >
            2) && (
          <div className="errorText" id="bodily_injury_lost_wages_error">
            Error: Invalid amount entry. Please enter value between 0.00 and
            10000000.00
          </div>
        )}
      </Box>

      <Box sx={{ maxWidth: "21.875rem" }}>
        <div className="formLabel">
          <label htmlFor="medicalExpenses">Medical expenses (if any)</label>
        </div>
        <div className="input-icon">
          <input
            className={classNames(
              "adornedInput",
              parseFloat(data?.bodily_injury_medical_expenses!) > 10000000 ||
                data.bodily_injury_medical_expenses?.toString().split(".")[1]
                  ?.length! > 2
                ? "errorInput"
                : "input"
            )}
            {...register("bodily_injury_medical_expenses", {
              required: false,
              pattern: {
                value: /^\d{0,8}\.?\d{0,2}?$/,
                message:
                  "Invalid amount entry. Please enter value between 0.00 and 10000000.00",
              },
              onChange: (e) => {
                updateFields({
                  bodily_injury_medical_expenses: e.target.value.replace(
                    /[^0-9.]/gi,
                    ""
                  ),
                });
              },
            })}
            id="medicalExpenses"
            value={data.bodily_injury_medical_expenses}
            aria-describedby="bodily_injury_medical_expense_error"
          />
          <i>$</i>
        </div>

        {(parseFloat(data.bodily_injury_medical_expenses!) > 10000000 ||
          data.bodily_injury_medical_expenses?.toString().split(".")[1]
            ?.length! > 2) && (
          <div className="errorText" id="bodily_injury_medical_expense_error">
            Error: Invalid amount entry. Please enter value between 0.00 and
            10000000.00
          </div>
        )}
      </Box>

      <Box sx={{ maxWidth: "21.875rem" }}>
        <div className="formLabel">
          <label htmlFor="otherLosses">Other losses</label>
        </div>
        <div className="input-icon">
          <input
            className={classNames(
              "adornedInput",
              parseFloat(data?.bodily_injury_other_losses!) > 10000000 ||
                data.bodily_injury_other_losses?.toString().split(".")[1]
                  ?.length! > 2
                ? "errorInput"
                : "input"
            )}
            {...register("bodily_injury_other_losses", {
              required: false,
              pattern: {
                value: /^\d{0,8}\.?\d{0,2}?$/,
                message:
                  "Invalid amount entry. Please enter value between 0.00 and 10000000.00",
              },
              onChange: (e) => {
                updateFields({
                  bodily_injury_other_losses: e.target.value.replace(
                    /[^0-9.]/gi,
                    ""
                  ),
                });
              },
            })}
            id="otherLosses"
            value={data.bodily_injury_other_losses}
            aria-describedby="bodily_injury_other_losses_error"
          />
          <i>$</i>
        </div>

        {(parseFloat(data.bodily_injury_other_losses!) > 10000000 ||
          data.bodily_injury_other_losses?.toString().split(".")[1]?.length! >
            2) && (
          <div className="errorText" id="bodily_injury_other_losses_error">
            Error: Invalid amount entry. Please enter value between 0.00 and
            10000000.00
          </div>
        )}
      </Box>

      <div className="formLabel">
        <label htmlFor="other">Please describe other losses</label>
      </div>
      <textarea
        id="other"
        maxLength={250}
        rows={8}
        cols={46}
        value={data.bodily_injury_other_desc}
        {...register("bodily_injury_other_desc")}
        onChange={(e) =>
          updateFields({
            bodily_injury_other_desc: e.target.value
              .trimStart()
              .replace(/[~`!^;|\\":<>?]/gi, ""),
          })
        }
      />
      <div>
        You have <b>{250 - data.bodily_injury_other_desc!.length}</b> characters
        remaining
      </div>

      <Box sx={{ maxWidth: "21.875rem" }}>
        <div className="formLabel">
          <label htmlFor="subTotalebodilyinjury">Subtotal</label>
        </div>
        <OutlinedInput
          disabled={true}
          fullWidth
          type="number"
          sx={{
            height: "44px",
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#4f4f4f",
            },
          }}
          inputProps={{ min: "0", step: ".01" }}
          id="subTotalebodilyinjury"
          size="small"
          value={bodilyInjurySubTotal.toFixed(2)}
          startAdornment={<InputAdornment position="start">$</InputAdornment>}
        />
      </Box>
      <br />
      <br />
    </>
  );
};

export const IncidentInvolves: React.FC<Props> = ({
  errors,
  register,
  resetField,
  data,
  updateFields,
  setItemList,
}) => {
  useEffect(() => {
    if (data.incident_involves === "PropertyDamage") {
      data.bodily_injury_lost_wages = "";
      data.bodily_injury_medical_expenses = "";
      data.bodily_injury_other_losses = "";
      data.bodily_injury_other_desc = "";
    }
    if (data.incident_involves === "PropertyDamage") {
      data.bodily_injury_lost_wages = "";
      data.bodily_injury_medical_expenses = "";
      data.bodily_injury_other_losses = "";
      data.bodily_injury_other_desc = "";
    }
  }, [data.incident_involves]);

  function resetFields() {
    setItemList([]);
    resetField("bodily_injury_medical_expenses");
    resetField("bodily_injury_lost_wages");
    resetField("bodily_injury_other_losses");
    resetField("bodily_injury_other_desc");
    data.bodily_injury_lost_wages = "";
    data.bodily_injury_medical_expenses = "";
    data.bodily_injury_other_losses = "";
    data.bodily_injury_other_desc = "";
  }
  return (
    <>
      <div className="formLabel">
        <label id="incidentInvolves">*Incident involves</label>
      </div>
      <RadioGroup
        style={{ marginTop: "5px", marginBottom: "10px" }}
        role="radiogroup"
        aria-labelledby="incidentInvolves"
        aria-describedby="incidentInvolves_error"
        onChange={() => {
          resetFields();
        }}
      >
        <FormControlLabel
          sx={{ height: "28px" }}
          id="incidentInvolves_error"
          {...register("incident_involves", {
            required: true,
            onChange: (e: any) => {
              resetFields();
              updateFields({ incident_involves: e.target.value });
            },
            validate: (value: any) =>
              value === "BodilyInjury" ||
              value === "PropertyDamage" ||
              value === "Both",
          })}
          checked={data.incident_involves === "BodilyInjury"}
          value="BodilyInjury"
          control={
            <Radio sx={errors.incident_involves && { color: "#e2231a" }} />
          }
          label="Bodily Injury"
        />
        <FormControlLabel
          {...register("incident_involves", {
            required: true,
            onChange: (e: any) => {
              resetFields();
              updateFields({ incident_involves: e.target.value });
            },
            validate: (value: any) =>
              value === "BodilyInjury" ||
              value === "PropertyDamage" ||
              value === "Both",
          })}
          checked={data.incident_involves === "PropertyDamage"}
          value="PropertyDamage"
          control={
            <Radio sx={errors.incident_involves && { color: "#e2231a" }} />
          }
          label="Property damage/Food spoilage"
          id="incidentInvolves_error"
        />
        <FormControlLabel
          sx={{ height: "28px" }}
          id="incidentInvolves_error"
          {...register("incident_involves", {
            required: true,
            onChange: (e: any) => {
              resetFields();
              updateFields({ incident_involves: e.target.value });
            },
            validate: (value: any) =>
              value === "BodilyInjury" ||
              value === "PropertyDamage" ||
              value === "Both",
          })}
          checked={data.incident_involves === "Both"}
          value="Both"
          control={
            <Radio sx={errors.incident_involves && { color: "#e2231a" }} />
          }
          label="Both"
        />
        {errors.incident_involves && (
          <div className="errorText" id="incidentInvolves_error">
            Error: Please choose one option for Incident involves
          </div>
        )}
      </RadioGroup>
    </>
  );
};

export const VehicleOwnerInfo: React.FC<Props> = ({
  data,
  updateFields,
  errors,
  register,
}) => {
  return (
    <>
      <br />
      <br />
      <h2 className="subheader">Vehicle owner information</h2>

      <div className="formLabel">
        <label htmlFor="vehicleOwnerFirstName">
          *Vehicle owner's first name
        </label>
      </div>

      <input
        id="vehicleOwnerFirstName"
        className={classNames(
          errors.vehicle_owner_first_name ? "errorInput" : "input"
        )}
        {...register("vehicle_owner_first_name", {
          required: true,
          onChange: (e) =>
            updateFields({
              vehicle_owner_first_name: e.target.value
                .trimStart()
                .replace(/[-~`!^;|\\":<>?()@$*{}+=]/gi, ""),
            }),
        })}
        value={data.vehicle_owner_first_name}
        maxLength={50}
        aria-describedby="vehicleOwnerFirstName_error"
      />
      {errors.vehicle_owner_first_name && (
        <div className="errorText" id="vehicleOwnerFirstName_error">
          Error: Vehicle owner's first name is required
        </div>
      )}
      <div className="formLabel">
        <label htmlFor="vehicleOwnerLastName">*Vehicle owner's last name</label>
      </div>

      <input
        id="vehicleOwnerLastName"
        className={classNames(
          errors.vehicle_owner_last_name ? "errorInput" : "input"
        )}
        {...register("vehicle_owner_last_name", {
          required: true,
          onChange: (e) =>
            updateFields({
              vehicle_owner_last_name: e.target.value
                .trimStart()
                .replace(/[-~`!^;|\\":<>?()@$*{}+=]/gi, ""),
            }),
        })}
        value={data.vehicle_owner_last_name}
        maxLength={50}
        aria-describedby="vehicleOwnerLastName_error"
      />
      {errors.vehicle_owner_last_name && (
        <div className="errorText" id="vehicleOwnerLastName_error">
          Error: Vehicle owner's last name is required
        </div>
      )}
      <div className="formLabel">
        <label htmlFor="vehicleOwnerAddressLine1">*Address</label>
      </div>

      <input
        id="vehicleOwnerAddressLine1"
        className={classNames(
          errors.vehicle_owner_address_line1 ? "errorInput" : "input"
        )}
        {...register("vehicle_owner_address_line1", {
          required: true,
          onChange: (e) =>
            updateFields({
              vehicle_owner_address_line1: e.target.value
                .trimStart()
                .replace(/[-~`!^;|\\":<>?()@$*{}+=]/gi, ""),
            }),
        })}
        value={data.vehicle_owner_address_line1}
        maxLength={100}
        aria-describedby="vehicleOwnerAddressLine1_error"
      />
      {errors.vehicle_owner_address_line1 && (
        <div className="errorText" id="vehicleOwnerAddressLine1_error">
          Error: Vehicle owner's address is required
        </div>
      )}
      <div className="formLabel">
        <label htmlFor="vehicleOwnerAddressLine2">Apt/Suite:</label>
      </div>
      <input
        id="vehicleOwnerAddressLine2"
        className="input"
        value={data.vehicle_owner_address_line2}
        {...register("vehicle_owner_address_line2")}
        onChange={(e) =>
          updateFields({
            vehicle_owner_address_line2: e.target.value
              .trimStart()
              .replace(/[-~`!^;|\\":<>?()@$*{}+=]/gi, ""),
          })
        }
      />
      <div className="formLabel">
        <label htmlFor="vehicleOwnerCity">*City</label>
      </div>
      <input
        id="vehicleOwnerCity"
        className={classNames(
          errors.vehicle_owner_city ? "errorInput" : "input"
        )}
        {...register("vehicle_owner_city", {
          required: true,
          onChange: (e) =>
            updateFields({
              vehicle_owner_city: e.target.value
                .trimStart()
                .replace(/[-~`!^;|\\":<>?()@$*{}+=]/gi, ""),
            }),
        })}
        value={data.vehicle_owner_city}
        maxLength={50}
        aria-describedby="vehicleOwnerCity_error"
      />
      {errors.vehicle_owner_city && (
        <div className="errorText" id="vehicleOwnerCity_error">
          Error: Vehicle owner’s city is required
        </div>
      )}
      <div className="formLabel">
        <label htmlFor="ownerState">*State</label>
      </div>
      <select
        className="inputSmallState"
        id="ownerState"
        aria-describedby="ownerState_error"
        {...register("vehicle_owner_state", {
          required: true,
          onChange: (e) =>
            updateFields({ vehicle_owner_state: e.target.value }),
        })}
        value={data.vehicle_owner_state}
      >
        <option value="">Select</option>
        {stateConfig.map((state, index) => (
          <option key={index} value={state.key}>
            {state.key}
          </option>
        ))}
      </select>

      {errors.vehicle_owner_state && (
        <div className="errorText" id="ownerState_error">
          Error: Vehicle owner's state is required
        </div>
      )}
      <div className="formLabel">
        <label htmlFor="vehicleOwnerZip">ZIP code</label>
      </div>
      <input
        id="vehicleOwnerZip"
        className={classNames(
          data.vehicle_owner_zip!.length > 0 &&
            data.vehicle_owner_zip!.length !== 5
            ? "errorInput"
            : "input"
        )}
        {...register("vehicle_owner_zip", {
          pattern: {
            value: /\d{5}/,
            message: "Error: Please enter a valid ZIP code",
          },
          onChange: (e) =>
            updateFields({
              vehicle_owner_zip: e.target.value.trim().replace(/[^0-9 ]/gi, ""),
            }),
        })}
        value={data.vehicle_owner_zip}
        maxLength={5}
        aria-describedby="vehicle_owner_zip_error"
      />
      {data.vehicle_owner_zip!.length > 0 &&
      data.vehicle_owner_zip!.length !== 5 ? (
        <div className="errorText" id="vehicle_owner_zip_error">
          Error: Please enter a valid ZIP code (ex: 12345, 94538)
        </div>
      ) : null}
      <div className="formLabel">
        <label htmlFor="PrimaryPhone">*Primary phone number</label>
      </div>
      <input
        id="PrimaryPhone"
        maxLength={14}
        value={phoneFormat(data.vehicle_owner_phone)}
        className={classNames(
          errors.vehicle_owner_phone ? "errorInput" : "input"
        )}
        {...register("vehicle_owner_phone", {
          required: true,
          pattern: {
            value: /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/,
            message: "Error: Please enter a valid phone number",
          },
          onChange: (e) => {
            updateFields({ vehicle_owner_phone: e.target.value });
          },
        })}
        aria-describedby="PrimaryPhone_error"
      />
      {errors.vehicle_owner_phone &&
        data.vehicle_owner_phone!.length !== 14 && (
          <div className="errorText" id="PrimaryPhone_error">
            Error: Vehicle owner’s phone is required
          </div>
        )}
      <br />
      <br />
    </>
  );
};

export const VehicleInfo: React.FC<Props> = ({
  data,
  updateFields,
  errors,
  register,
  resetField,
}) => {
  useEffect(() => {
    if (data?.estimated_damage!.length > 11) {
      data.estimated_damage = "";
    }
  }, [data, data?.estimated_damage]);

  function resetFields() {
    data.vehicle_owner_zip = "";
    resetField("vehicle_owner_first_name");
    resetField("vehicle_owner_last_name");
    resetField("vehicle_owner_address_line1");
    resetField("vehicle_owner_address_line2");
    resetField("vehicle_owner_city");
    resetField("vehicle_owner_state");
    resetField("vehicle_owner_phone");
    resetField("vehicle_owner_zip");
  }
  return (
    <>
      <h2 className="subheader">Vehicle info</h2>

      <div className="formLabel">
        <label htmlFor="CarMake">*Car make</label>
      </div>
      <input
        id="CarMake"
        className={classNames(errors.vehicle_make ? "errorInput" : "input")}
        {...register("vehicle_make", {
          required: true,
          onChange: (e) => {
            updateFields({
              vehicle_make: e.target.value
                .trimStart()
                .replace(/[-~`!^;|\\":<>?()@$*{}+=]/gi, ""),
            });
          },
        })}
        value={data.vehicle_make}
        aria-describedby="CarMake_error"
      />
      {errors.vehicle_make && (
        <div className="errorText" id="CarMake_error">
          Error: Car make is required
        </div>
      )}
      <div className="formLabel">
        <label htmlFor="CarModel">*Car model</label>
      </div>
      <input
        id="CarModel"
        className={classNames(errors.vehicle_model ? "errorInput" : "input")}
        {...register("vehicle_model", {
          required: true,
          onChange: (e) =>
            updateFields({
              vehicle_model: e.target.value
                .trimStart()
                .replace(/[-~`!^;|\\":<>?()@$*{}+=]/gi, ""),
            }),
        })}
        value={data.vehicle_model}
        maxLength={50}
        aria-describedby="CarModel_error"
      />
      {errors.vehicle_model && (
        <div className="errorText" id="CarModel_error">
          Error: Car model is required
        </div>
      )}

      <div className="formLabel">
        <label htmlFor="CarYear">*Car year</label>
      </div>
      <input
        id="CarYear"
        className={classNames(errors.vehicle_year ? "errorInput" : "input")}
        {...register("vehicle_year", {
          required: true,
          onChange: (e) => updateFields({ vehicle_year: e.target.value }),
        })}
        type="number"
        onInput={(e) => {
          (e.target as HTMLInputElement).value = Math.max(
            0,
            parseInt((e.target as HTMLInputElement).value)
          )
            .toString()
            .slice(0, 4);
        }}
        value={data.vehicle_year}
        maxLength={4}
        aria-describedby="CarYear_error"
      />
      {errors.vehicle_year && (
        <div className="errorText" id="CarYear_error">
          Error: Car year is required
        </div>
      )}

      <Box sx={{ maxWidth: "21.875rem" }}>
        <div className="formLabel">
          <label htmlFor="EstimatedDamages">*Estimated damages</label>
        </div>
        <div className="input-icon">
          <input
            className={classNames(
              "adornedInput",
              parseFloat(data?.estimated_damage!) > 10000000 ||
                data.estimated_damage?.toString().split(".")[1]?.length! > 2 ||
                (errors.estimated_damage && !data.estimated_damage)
                ? "errorInput"
                : "input"
            )}
            {...register("estimated_damage", {
              required: true,
              pattern: {
                value: /^\d{0,8}\.?\d{0,2}?$/,
                message:
                  "Invalid amount entry. Please enter value between 0.00 and 10000000.00",
              },
              onChange: (e) => {
                updateFields({
                  estimated_damage: e.target.value.replace(/[^0-9.]/gi, ""),
                });
              },
            })}
            id="EstimatedDamages"
            value={data.estimated_damage}
            aria-describedby="EstimatedDamages_error"
          />
          <i>$</i>
        </div>
        {errors.estimated_damage && !data.estimated_damage && (
          <div className="errorText" id="EstimatedDamages_error">
            Error: Estimated damages is required.
          </div>
        )}

        {(parseFloat(data?.estimated_damage!) > 10000000 ||
          data.estimated_damage?.toString().split(".")[1]?.length! > 2) && (
          <div className="errorText" id="EstimatedDamages_error">
            Error: Invalid amount entry. Please enter value between 0.00 and
            10000000.00
          </div>
        )}
      </Box>

      <div className="formLabel">
        <label htmlFor="vehicleLicensePlate">*License plate number</label>
      </div>

      <input
        id="vehicleLicensePlate"
        className={classNames(
          errors.vehicle_license_plate ? "errorInput" : "input"
        )}
        {...register("vehicle_license_plate", {
          required: true,
          onChange: (e) =>
            updateFields({
              vehicle_license_plate: e.target.value
                .trimStart()
                .replace(/[-~`!^;|\\":<>?()@$*{}+=]/gi, ""),
            }),
        })}
        value={data.vehicle_license_plate}
        maxLength={10}
        aria-describedby="vehicleLicensePlate_error"
      />
      {errors.vehicle_license_plate && (
        <div className="errorText" id="vehicleLicensePlate_error">
          Error: License plate number is required.
        </div>
      )}

      <div className="formLabel">
        <label htmlFor="StateRegistered">State registered </label>
      </div>
      <select
        className="inputSmallState"
        id="StateRegistered"
        {...register("vehicle_state_registered", {
          required: true,
          onChange: (e) =>
            updateFields({ vehicle_state_registered: e.target.value }),
        })}
        defaultValue={data.vehicle_state_registered}
        value={data.vehicle_state_registered}
      >
        {stateConfig.map((state, index) => (
          <option key={index} value={state.key}>
            {" "}
            {state.key}
          </option>
        ))}
      </select>

      <div className="formLabel">
        <label id="claimantownerofvehicle">
          *Is claimant owner of vehicle?
        </label>
      </div>
      <RadioGroup
        role="radiogroup"
        aria-labelledby="claimantownerofvehicle"
        aria-describedby="claimantownerofvehicle_error"
        style={{ marginTop: "5px", marginBottom: "10px" }}
        value={data.claimant_owner_of_vehicle}
      >
        <FormControlLabel
          sx={{ height: "28px" }}
          value="Yes"
          control={<Radio />}
          {...register("claimant_owner_of_vehicle", {
            required: true,
            onChange: (e) => {
              resetFields();
              updateFields({ claimant_owner_of_vehicle: e.target.value });
            },
            validate: (value) => value === "No" || value === "Yes",
          })}
          label="Yes"
          id="claimantownerofvehicle_error"
        />
        <FormControlLabel
          {...register("claimant_owner_of_vehicle", {
            required: true,
            onChange: (e) => {
              resetFields();
              updateFields({ claimant_owner_of_vehicle: e.target.value });
            },
            validate: (value) => value === "No" || value === "Yes",
          })}
          value="No"
          control={<Radio />}
          label="No"
          id="claimantownerofvehicle_error"
        />
      </RadioGroup>
      {errors.claimant_owner_of_vehicle && (
        <div className="errorText" id="claimantownerofvehicle_error">
          Error: Please choose one option for Is this a claimant owner of
          vehecle?
        </div>
      )}
    </>
  );
};

export const MotorVehicleIncident: React.FC<Props> = ({
  data,
  updateFields,
  errors,
  resetField,
  register,
}) => {
  function resetFields() {
    resetField("vehicle_make");
    resetField("vehicle_model");
    resetField("vehicle_year");
    resetField("estimated_damage");
    resetField("vehicle_license_plate");
    resetField("vehicle_state_registered");
    data.vehicle_license_plate = "";
    data.vehicle_owner_first_name = "";
    data.vehicle_owner_last_name = "";
    data.vehicle_owner_address_line1 = "";
    data.vehicle_owner_address_line2 = "";
    data.vehicle_owner_city = "";
    data.vehicle_owner_state = "";
    data.vehicle_owner_phone = "";
    data.vehicle_owner_zip = "";
    data.claimant_owner_of_vehicle = "Yes";
    resetField("vehicle_owner_first_name");
    resetField("vehicle_owner_last_name");
    resetField("vehicle_owner_address_line1");
    resetField("vehicle_owner_address_line2");
    resetField("vehicle_owner_city");
    resetField("vehicle_owner_state");
    resetField("vehicle_owner_phone");
  }
  return (
    <>
      <div className="formLabel">
        <label id="motorvehicleincident">
          *Is this a motor vehicle incident?
        </label>
      </div>

      <RadioGroup
        style={{ marginTop: "5px", marginBottom: "10px" }}
        role="radiogroup"
        aria-labelledby="motorvehicleincident"
        aria-describedby="motorvehicleincident_error"
      >
        <FormControlLabel
          sx={{ height: "28px" }}
          {...register("motor_vehicle_accident", {
            required: true,
            onChange: (e) => {
              resetFields();
              updateFields({ motor_vehicle_accident: e.target.value });
            },
            validate: (value) => value === "No" || value === "Yes",
          })}
          value="Yes"
          checked={data.motor_vehicle_accident === "Yes"}
          control={
            <Radio sx={errors.motor_vehicle_accident && { color: "#e2231a" }} />
          }
          label="Yes"
          id="motorvehicleincident_error"
        />
        <FormControlLabel
          {...register("motor_vehicle_accident", {
            required: true,
            onChange: (e) => {
              resetFields();
              updateFields({ motor_vehicle_accident: e.target.value });
            },
            validate: (value) => value === "No" || value === "Yes",
          })}
          value="No"
          checked={data.motor_vehicle_accident === "No"}
          control={
            <Radio sx={errors.motor_vehicle_accident && { color: "#e2231a" }} />
          }
          label="No"
          id="motorvehicleincident_error"
        />
      </RadioGroup>
      {errors.motor_vehicle_accident && (
        <div className="errorText" id="motorvehicleincident_error">
          Error: Choose one option. Is this a motor vehicle incident?
        </div>
      )}
    </>
  );
};

export const WereYouInjured: React.FC<BodilyInjuryProps> = ({
  data,
  updateFields,
  bodilyInjurySubTotal,
  errors,
  resetField,
  register,
}) => {
  useEffect(() => {
    if (data.were_you_injured === "No") {
      data.bodily_injury_lost_wages = "";
      data.bodily_injury_medical_expenses = "";
      data.bodily_injury_other_losses = "";
      data.bodily_injury_other_losses = "";
      data.bodily_injury_other_desc = "";
    }
  }, [data.were_you_injured]);
  function resetFields() {
    resetField("bodily_injury_medical_expenses");
    resetField("bodily_injury_lost_wages");
    resetField("bodily_injury_other_losses");
    resetField("bodily_injury_other_desc");
  }
  return (
    <>
      <div className="formLabel">
        <label id="Wereyouinjured">*Were you injured?</label>
      </div>
      <RadioGroup
        role="radiogroup"
        aria-labelledby="Wereyouinjured"
        style={{ marginTop: "5px", marginBottom: "10px" }}
      >
        <FormControlLabel
          sx={{ height: "28px" }}
          {...register("were_you_injured", {
            required: true,
            onChange: (e) => {
              resetFields();
              updateFields({ were_you_injured: e.target.value });
            },
            validate: (value) => value === "No" || value === "Yes",
          })}
          value="Yes"
          checked={data.were_you_injured === "Yes"}
          control={
            <Radio sx={errors.were_you_injured && { color: "#e2231a" }} />
          }
          label="Yes"
          id="motorvehicleincident"
        />
        <FormControlLabel
          {...register("were_you_injured", {
            required: true,
            onChange: (e) => {
              resetFields();
              updateFields({ were_you_injured: e.target.value });
            },
            validate: (value) => value === "No" || value === "Yes",
          })}
          value="No"
          checked={data.were_you_injured === "No"}
          control={
            <Radio sx={errors.were_you_injured && { color: "#e2231a" }} />
          }
          label="No"
          id="motorvehicleincident"
        />
      </RadioGroup>
    </>
  );
};

export const ClaimForYourBusiness: React.FC<Props> = ({
  data,
  updateFields,
  errors,
  register,
  resetField,
  setItemList,
}) => {
  const resetTable = () => {
    setItemList([]);
  };

  function resetFields() {
    data.estimated_revenue_losses = "";
    data.wages_paid_to_employee = "";
    data.lost_inventory = "";
    resetField("estimated_revenue_losses");
    resetField("wages_paid_to_employee");
    resetField("lost_inventory");
  }
  return (
    <>
      <br />
      <div className="formLabel">
        <label id="claimforyourbusiness">
          *Is this a claim for your business?
        </label>
      </div>
      <RadioGroup
        style={{ marginTop: "5px", marginBottom: "10px" }}
        role="radiogroup"
        aria-labelledby="claimforyourbusiness"
        aria-describedby="claimforyourbusiness_error"
      >
        <FormControlLabel
          sx={{ height: "28px", fontSize: "16" }}
          {...register("claim_for_business", {
            required: true,
            onChange: (e) => {
              resetFields();
              updateFields({ claim_for_business: e.target.value });
            },
            validate: (value) => value === "No" || value === "Yes",
          })}
          value="Yes"
          checked={data.claim_for_business === "Yes"}
          control={
            <Radio sx={errors.claim_for_business && { color: "#e2231a" }} />
          }
          label={<span style={{ fontSize: "16" }}>Yes</span>}
          id="claimforyourbusiness_error"
        />
        <FormControlLabel
          {...register("claim_for_business", {
            required: true,
            onChange: (e) => {
              resetFields();
              updateFields({ claim_for_business: e.target.value });

              resetTable();
            },
            validate: (value) => value === "No" || value === "Yes",
          })}
          value="No"
          checked={data.claim_for_business === "No"}
          control={
            <Radio sx={errors.claim_for_business && { color: "#e2231a" }} />
          }
          label="No"
          id="claimforyourbusiness_error"
        />
        {errors.claim_for_business && (
          <div className="errorText" id="claimforyourbusiness_error">
            Error: Choose one option. Is this a claim for your business?
          </div>
        )}
      </RadioGroup>
    </>
  );
};

export const ClaimForYourBusinessFields: React.FC<Props> = ({
  data,
  updateFields,
  errors,
  register,
}) => {
  useEffect(() => {
    if (data?.estimated_revenue_losses!.length > 11) {
      data.estimated_revenue_losses = "";
    }
    if (data?.wages_paid_to_employee!.length > 11) {
      data.wages_paid_to_employee = "";
    }
    if (data?.lost_inventory!.length > 11) {
      data.lost_inventory = "";
    }
  }, [
    data,
    data?.estimated_revenue_losses,
    data?.wages_paid_to_employee,
    data.lost_inventory,
  ]);

  return (
    <>
      <div className="formLabel">
        <label htmlFor="BusinessName">*Business name</label>
      </div>
      <input
        id="BusinessName"
        className={classNames(errors.business_name ? "errorInput" : "input")}
        {...register("business_name", {
          required: true,
          onChange: (e) =>
            updateFields({
              business_name: e.target.value
                .trimStart()
                .replace(/[-~`!^;|\\":<>?()@$*{}+=]/gi, ""),
            }),
        })}
        value={data.business_name}
        aria-describedby="BusinessName_error"
      />
      {errors.business_name && (
        <div className="errorText" id="BusinessName_error">
          Error: Business name is required
        </div>
      )}
      <Box sx={{ maxWidth: "21.875rem" }}>
        <div className="formLabel">
          <label htmlFor="EstimatedRevenueLoss">Estimated revenue loss</label>
        </div>
        <div className="input-icon">
          <input
            className={classNames(
              "adornedInput",
              parseFloat(data?.estimated_revenue_losses!) > 10000000 ||
                data.estimated_revenue_losses?.toString().split(".")[1]
                  ?.length! > 2
                ? "errorInput"
                : "input"
            )}
            {...register("estimated_revenue_losses", {
              required: false,
              pattern: {
                value: /^\d{0,8}\.?\d{0,2}?$/,
                message:
                  "Invalid amount entry. Please enter value between 0.00 and 10000000.00",
              },
              onChange: (e) => {
                updateFields({
                  estimated_revenue_losses: e.target.value.replace(
                    /[^0-9.]/gi,
                    ""
                  ),
                });
              },
            })}
            id="EstimatedRevenueLoss"
            value={data.estimated_revenue_losses}
            aria-describedby="estimated_revenue_losses_error"
          />
          <i>$</i>
        </div>
        {(parseFloat(data?.estimated_revenue_losses!) > 10000000 ||
          data.estimated_revenue_losses?.toString().split(".")[1]?.length! >
            2) && (
          <div className="errorText" id="estimated_revenue_losses_error">
            Error: Invalid amount entry. Please enter value between 0.00 and
            10000000.00
          </div>
        )}
      </Box>
      <Box sx={{ maxWidth: "21.875rem" }}>
        <div className="formLabel">
          <label htmlFor="WagesPaidtoHourlyEmployees">
            Wages paid to hourly employees
          </label>
        </div>
        <div className="input-icon">
          <input
            className={classNames(
              "adornedInput",
              parseFloat(data?.wages_paid_to_employee!) > 10000000 ||
                data.wages_paid_to_employee?.toString().split(".")[1]?.length! >
                  2
                ? "errorInput"
                : "input"
            )}
            id="WagesPaidtoHourlyEmployees"
            value={data.wages_paid_to_employee}
            {...register("wages_paid_to_employee", {
              required: false,
              pattern: {
                value: /^\d{0,8}\.?\d{0,2}?$/,
                message:
                  "Invalid amount entry. Please enter value between 0.00 and 10000000.00",
              },
              onChange: (e) =>
                updateFields({
                  wages_paid_to_employee: e.target.value.replace(
                    /[^0-9.]/gi,
                    ""
                  ),
                }),
            })}
            aria-describedby="wages_paid_to_employee_error"
          />
          <i>$</i>
        </div>
        {(parseFloat(data.wages_paid_to_employee!) > 10000000 ||
          data.wages_paid_to_employee?.toString().split(".")[1]?.length! >
            2) && (
          <div className="errorText" id="wages_paid_to_employee_error">
            Error: Invalid amount entry. Please enter value between 0.00 and
            10000000.00
          </div>
        )}
      </Box>
      <Box sx={{ maxWidth: "21.875rem" }}>
        <div className="formLabel">
          <label htmlFor="LostInventory">Lost inventory</label>
        </div>
        <div className="input-icon">
          <input
            className={classNames(
              "adornedInput",
              parseFloat(data?.lost_inventory!) > 10000000 ||
                data.lost_inventory?.toString().split(".")[1]?.length! > 2
                ? "errorInput"
                : "input"
            )}
            {...register("lost_inventory", {
              required: false,
              pattern: {
                value: /^\d{0,8}\.?\d{0,2}?$/,
                message:
                  "Invalid amount entry. Please enter value between 0.00 and 10000000.00",
              },
              onChange: (e) =>
                updateFields({
                  lost_inventory: e.target.value.replace(/[^0-9.]/gi, ""),
                }),
            })}
            aria-describedby="lost_inventory"
            id="LostInventory"
            value={data.lost_inventory}
          />
          <i>$</i>
        </div>
        {(parseFloat(data.lost_inventory!) > 10000000 ||
          data.lost_inventory?.toString().split(".")[1]?.length! > 2) && (
          <div className="errorText" id="lost_inventory">
            Error: Invalid amount entry. Please enter value between 0.00 and
            10000000.00
          </div>
        )}
      </Box>
      <br />
      <br />
    </>
  );
};
