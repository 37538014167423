import { useState, useEffect } from "react";
import {
  Grid,
  Box,
  Table,
  TableContainer,
  Paper,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  tableCellClasses,
} from "@mui/material";
import { ItemList, UserData } from "../../../types/user-data";

import { AddItemPopup } from "./AddItemPopup";
import { DeleteItemPopup } from "./DeleteItemPopup";
import { EditItemPopup } from "./EditItemPopup";
import "../../styles.css";
import { StyledTableRow } from "../../../utils/common-function";

interface Props extends UserData {
  ItemListData: ItemList[];
  setItemList: (fields: any) => void;
}
interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

interface Data {
  make: string;
  Model: string;
  Age: string;
  Amount: string;
  Description: string;
  Action: string;
}

const headCells: readonly HeadCell[] = [
  {
    id: "make",
    numeric: false,
    disablePadding: true,
    label: " Make/Brand",
  },
  {
    id: "Model",
    numeric: true,
    disablePadding: false,
    label: "Model",
  },
  {
    id: "Age",
    numeric: true,
    disablePadding: false,
    label: "Age",
  },
  {
    id: "Amount",
    numeric: true,
    disablePadding: false,
    label: "*Amount",
  },
  {
    id: "Description",
    numeric: true,
    disablePadding: false,
    label: "*Item Description",
  },
  {
    id: "Action",
    numeric: true,
    disablePadding: false,
    label: "Action",
  },
];
interface EnhancedTableProps {
  rowCount: number;
}
function EnhancedTableHead(props: EnhancedTableProps) {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            style={{
              fontWeight: 700,
              color: "white",
              backgroundColor: "#0C7DBB",
              paddingLeft: "20px",
            }}
            key={headCell.id}
            align={"left"}
            padding={headCell.disablePadding ? "none" : "normal"}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export const TableView: React.FC<Props> = ({ ItemListData, setItemList }) => {
  const [itemPopupData, setItemPopupData] = useState({});
  function addItemPopupFields(fields: Partial<ItemList>) {
    setItemPopupData(fields);
  }
  const handleDelete = (index: any) => {
    const temp = [...ItemListData];

    temp.splice(index, 1);

    setItemList(temp);
  };
  useEffect(() => {
    if (Object.keys(itemPopupData).length > 0) {
      setItemList((prev: any) => {
        return [...prev, itemPopupData];
      });
    }
  }, [itemPopupData, setItemList]);

  return (
    <>
      <br />
      <h2 className="subheader">Property damage/Food spoilage</h2>
      <Box sx={{ width: "100%" }}>
        <div className="formLabel">Damaged items (other than vehicle)</div>
        {ItemListData.length > 0 ? (
          <>
            <Paper sx={{ width: "100%", mb: 2 }}>
              <TableContainer sx={{ maxHeight: 300 }}>
                <Table
                  stickyHeader
                  sx={{
                    [`& .${tableCellClasses.root}`]: {
                      borderBottom: "none",
                    },
                  }}
                >
                  <EnhancedTableHead rowCount={ItemListData.length} />
                  <TableBody>
                    {ItemListData?.map((row, index) => (
                      <StyledTableRow key={index}>
                        <TableCell component="th" scope="row">
                          {row.make}
                        </TableCell>
                        <TableCell align="left">{row.model}</TableCell>
                        <TableCell align="left">{row.age}</TableCell>

                        <TableCell align="left">{row.amount}</TableCell>
                        <TableCell align="left">{row.description}</TableCell>
                        <TableCell>
                          <div className="row">
                            <EditItemPopup
                              {...ItemListData}
                              ItemListData={ItemListData}
                              index={index}
                              row={row}
                              updateItemListFields={setItemList}
                            />

                            <DeleteItemPopup
                              {...itemPopupData}
                              index={index}
                              handleDelete={handleDelete}
                            />
                          </div>
                        </TableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>

            <div>
              <AddItemPopup
                {...ItemListData}
                ItemListData={ItemListData}
                {...itemPopupData}
                addItemPopupFields={addItemPopupFields}
              />
            </div>

            <br />
            <div>
              Note: If you have more than 10 items, please add a list of items
              as an attachment in step 4.
            </div>
          </>
        ) : (
          <div>
            <AddItemPopup
              {...ItemListData}
              ItemListData={ItemListData}
              {...itemPopupData}
              addItemPopupFields={addItemPopupFields}
            />
          </div>
        )}
      </Box>
    </>
  );
};

export const MobileTableView: React.FC<Props> = ({
  ItemListData,
  setItemList,
}) => {
  const [itemPopupData, setItemPopupData] = useState({});
  function addItemPopupFields(fields: Partial<ItemList>) {
    setItemPopupData(fields);
  }

  const handleDelete = (index: any) => {
    const tempArray = [...ItemListData];

    tempArray.splice(index, 1);

    setItemList(tempArray);
  };
  useEffect(() => {
    if (Object.keys(itemPopupData).length > 0) {
      setItemList((prev: any) => {
        return [...prev, itemPopupData];
      });
    }
  }, [itemPopupData, setItemList]);

  return (
    <>
      <br />
      <div className="mobilesubheader">Property Damage or Food Spoilage</div>

      <div className="mobilesubheader">Damaged Items (other than vehicle)</div>
      <br />

      {ItemListData.length > 0 ? (
        <>
          <TableContainer
            sx={{ maxWidth: 380, maxHeight: 400 }}
            component={Paper}
          >
            <Table
              stickyHeader
              sx={{
                [`& .${tableCellClasses.root}`]: {
                  borderBottom: "none",
                },
                maxWidth: 380,
              }}
              aria-label="simple table"
            >
              <TableBody>
                {ItemListData.map((row, index) => (
                  <>
                    <TableRow>
                      <TableCell
                        sx={{ paddingLeft: "15px" }}
                        style={{
                          color: "white",
                          backgroundColor: "#0C7DBB",
                        }}
                      >
                        <div className="row">
                          <div>
                            <div style={{ fontWeight: "700" }}>Make/Brand</div>
                            <div>{row.make}</div>
                          </div>
                        </div>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th">
                        <div style={{ fontWeight: "700" }}>Model</div>
                        <div>{row.model}</div>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        component="th"
                        style={{
                          backgroundColor: "#F4F5F6",
                        }}
                      >
                        <div style={{ fontWeight: "700" }}>Age</div>
                        <div>{row.age}</div>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th">
                        <div style={{ fontWeight: "700" }}>*Amount</div>
                        <div>{row.amount}</div>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        component="th"
                        style={{
                          backgroundColor: "#F4F5F6",
                        }}
                      >
                        <div style={{ fontWeight: "700" }}>
                          *Item Description
                        </div>
                        <div>{row.description}</div>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th">
                        <div style={{ fontWeight: "700" }}>Action</div>
                        <div className="row">
                          <EditItemPopup
                            {...ItemListData}
                            ItemListData={ItemListData}
                            index={index}
                            row={row}
                            updateItemListFields={setItemList}
                          />
                          <DeleteItemPopup
                            {...itemPopupData}
                            index={index}
                            handleDelete={handleDelete}
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                  </>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <div style={{ marginTop: "20px" }}>
            <AddItemPopup
              {...ItemListData}
              ItemListData={ItemListData}
              {...itemPopupData}
              addItemPopupFields={addItemPopupFields}
            />
          </div>
          <br />
          <Grid item xs={11} sm={12} md={12} lg={12} xl={12}>
            <div>
              Note: If you have more than 10 items, please add a list of items
              as an attachment in step 4.
            </div>
          </Grid>
        </>
      ) : (
        <div>
          <AddItemPopup
            {...ItemListData}
            ItemListData={ItemListData}
            {...itemPopupData}
            addItemPopupFields={addItemPopupFields}
          />
        </div>
      )}
    </>
  );
};
