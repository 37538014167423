import { DialogTitle, IconButton, styled, TableRow } from "@mui/material";
import { ReactComponent as ClosePopupIcon } from "../Assets/ClosePopup.svg";

export function phoneFormat(input: any) {
  input = input.replace(/\D/g, "");
  let size = input.length;
  if (size > 0) {
    input = "(" + input;
  }
  if (size > 3) {
    input = input.slice(0, 4) + ") " + input.slice(4, 11);
  }
  if (size > 6) {
    input = input.slice(0, 9) + "-" + input.slice(9);
  }
  return input;
}

export function findtotal(
  claim_for_business: string | undefined,
  motor_vehicle_accident: string | undefined,
  were_you_injured: string | undefined,
  bodilyInjurySubTotal: number,
  businessSubTotal: number,
  estimatedDamageSubTotal: number,
  incident_involves: string | undefined,
  setTotal = (value: any) => {}
) {
  switch (true) {
    case claim_for_business === "Yes" &&
      (motor_vehicle_accident === "" || motor_vehicle_accident === "No") &&
      were_you_injured === "No":
      setTotal(businessSubTotal);
      break;

    case claim_for_business === "No" &&
      (motor_vehicle_accident === "" || motor_vehicle_accident === "No") &&
      incident_involves === "BodilyInjury":
      setTotal(bodilyInjurySubTotal);
      break;

    case claim_for_business === "No" &&
      (motor_vehicle_accident === "" || motor_vehicle_accident === "No") &&
      incident_involves === "Both":
      setTotal(bodilyInjurySubTotal);
      break;
    case claim_for_business === "No" &&
      (motor_vehicle_accident === "" || motor_vehicle_accident === "Yes") &&
      incident_involves === "Both":
      setTotal(bodilyInjurySubTotal + estimatedDamageSubTotal);
      break;

    case claim_for_business === "No" &&
      incident_involves === "BodilyInjury" &&
      motor_vehicle_accident === "Yes":
      setTotal(bodilyInjurySubTotal + estimatedDamageSubTotal);
      break;

    case claim_for_business === "No" && motor_vehicle_accident === "Yes":
      setTotal(estimatedDamageSubTotal);
      break;

    case claim_for_business === "Yes" &&
      (motor_vehicle_accident === "" || motor_vehicle_accident === "Yes") &&
      were_you_injured === "No":
      setTotal(businessSubTotal + estimatedDamageSubTotal);
      break;

    case claim_for_business === "Yes" &&
      (motor_vehicle_accident === "" || motor_vehicle_accident === "No") &&
      were_you_injured === "Yes":
      setTotal(businessSubTotal + bodilyInjurySubTotal);
      break;

    case claim_for_business === "No" &&
      (motor_vehicle_accident === "" || motor_vehicle_accident === "No") &&
      were_you_injured === "Yes":
      setTotal(bodilyInjurySubTotal);
      break;

    case claim_for_business === "No" &&
      (motor_vehicle_accident === "" || motor_vehicle_accident === "No") &&
      were_you_injured === "No":
      setTotal(0);
      break;
    case claim_for_business === "Yes" &&
      motor_vehicle_accident === "Yes" &&
      were_you_injured === "Yes":
      setTotal(
        businessSubTotal + bodilyInjurySubTotal + estimatedDamageSubTotal
      );
      break;
  }
}

interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}
export function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <ClosePopupIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#F4F5F6",
  },

  "& .MuiTableCell-root": {
    borderRight: "1px solid rgba(224, 224, 224, 1)",
  },
}));

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}
interface Data {
  FirstName: string;
  LastName: string;
  Address: string;
  City: string;
  State: string;
  Zip: string;
  Phone: string;
  PGEEmployee: string;
  Action: string;
}
export const headCells: readonly HeadCell[] = [
  {
    id: "FirstName",
    numeric: false,
    disablePadding: true,
    label: "*First Name",
  },
  {
    id: "LastName",
    numeric: true,
    disablePadding: false,
    label: "*Last Name",
  },
  {
    id: "Address",
    numeric: true,
    disablePadding: false,
    label: "Address",
  },
  {
    id: "City",
    numeric: true,
    disablePadding: false,
    label: "City",
  },
  {
    id: "State",
    numeric: true,
    disablePadding: false,
    label: "State",
  },
  {
    id: "Zip",
    numeric: true,
    disablePadding: false,
    label: "Zip",
  },
  {
    id: "Phone",
    numeric: true,
    disablePadding: false,
    label: "Phone",
  },
  {
    id: "PGEEmployee",
    numeric: true,
    disablePadding: false,
    label: "PGE Employee?",
  },
  {
    id: "Action",
    numeric: true,
    disablePadding: false,
    label: "Action",
  },
];
