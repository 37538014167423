import { ItemList,UserData, WitnessList } from "../types/user-data";

export const INITIAL_DATA: UserData = {
    business_name: "",
    first_name: "",
    last_name: "",
    is_pge_employee:"No",
    lan_id:"",
    account_number: "", 
    address_line1:"",
    address_line2:"",
    city: "",
    state: "CA",
    zip_code: "",
    primary_phone:"",
    alternate_phone:"",
    email: "",
    confirm_email: "", 
    insurance_carrier:"abc",
    incident_date:"",
    incident_time:"",
    same_as_mailing_address:"Yes",
    incident_address_line1:"",
    incident_address_line2:"",
    incident_city:"",
    incident_state:"CA",
    incident_zip_code:"",
    incident_description:"",
    describe_what_happened:"",
    were_you_injured:"No",
    vehicle_make:'',
    vehicle_model:'',
    vehicle_year:'',
    estimated_damage:'',
    vehicle_license_plate:'',
    vehicle_state_registered:'CA',
    vehicle_owner_first_name:'',
    vehicle_owner_last_name:'',
    vehicle_owner_address_line1:'',
    vehicle_owner_address_line2:'',
    vehicle_owner_city:'',
    vehicle_owner_state:'CA',
    vehicle_owner_zip:'',
    vehicle_owner_phone:'',
    incident_involves:"",
    claim_for_business:"",
    motor_vehicle_accident:"No",
    estimated_revenue_losses:"",
    wages_paid_to_employee:"",
    lost_inventory:"",
    bodily_injury_lost_wages:"",
    bodily_injury_medical_expenses:"",
    bodily_injury_other_losses:"",
    bodily_injury_other_desc:"",
    agreement_signed:'',
    claimant_owner_of_vehicle:'Yes',
    incident_location:"",
  }


  export const itemList: ItemList[] =[ {
    make:'',
    model:'',
    age:'',
    amount:'',
    description:'',
  }]


  export const INITIAL_DATA_ADD_ITEM: ItemList = {
    make: "",
    model: "",
    age: "",
    amount: "",
    description: "",
  };
  export const WitnessListData: WitnessList[] =[ {
    address_line1:'',
    first_name:'',
    last_name:'',
    city:'',
    pge_employee:'',
    phone_number:'',
    state:'',
    zip_code:'',
  }]
  export const claimId={
    claim_id:''
  }

