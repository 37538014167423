import * as React from "react";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";

import { Box } from "@mui/material";

import { ReactComponent as TrashIcon } from "../../../Assets/Trash.svg";
import classNames from "classnames";
import useCheckScreenSize from "../../../utils/checkScreenSize";

export interface Props {
  index: number;
  handleDelete: (index: number) => void;
}

export const DeleteItemPopup: React.FC<Props> = ({ index, handleDelete }) => {
  const [open, setOpen] = React.useState(false);
  const isMobile = useCheckScreenSize({ breakpoint: 850 });
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteSubmit = () => {
    handleDelete(index);
    setOpen(false);
  };

  return (
    <div id="alert-dialog">
      <button
        id="alert-dialog-title"
        aria-label="Delete"
        onClick={handleClickOpen}
        className="editButton"
        type="button"
      >
        <TrashIcon />
      </button>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            m: "auto",
            width: "fit-content",
          }}
          className={classNames(!isMobile && "popup")}
        >
          <DialogTitle id="alert-dialog-title">
            <h2 className="subheader">Delete item</h2>
          </DialogTitle>
          <DialogContent>
            <div id="alert-dialog-title">
              Would you like to delete the item?
            </div>
            <br />
            <br />

            <div style={{ marginTop: "10px" }}>
              <button
                className={classNames(
                  "yellowButton",
                  isMobile && "popupInputMobileItem"
                )}
                onClick={handleDeleteSubmit}
              >
                Delete
              </button>
            </div>
            <div className="secondButtonDiv">
              <button onClick={handleClose} className="secondButton">
                Cancel
              </button>
            </div>
          </DialogContent>
        </Box>
      </Dialog>
    </div>
  );
};
