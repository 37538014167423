import React from "react";
import classNames from "classnames";
import useCheckScreenSize from "../../utils/checkScreenSize";
import { Grid } from "@mui/material";
import "./Stepper.css";

export interface Props {
  activeStepIndex: number;
}

export const array = [
  { labelId: "1. Claimant information", displayOrder: 1, path: "/" },
  {
    labelId: "2. Incident information",
    displayOrder: 2,
    path: "/IncidentInfo",
  },
  { labelId: "3. Claim details", displayOrder: 3, path: "/ClaimDetails" },
  { labelId: "4. Witnesses", displayOrder: 4, path: "/Witnesses" },
  {
    labelId: "5. Supporting documents",
    displayOrder: 5,
    path: "/SupportingDocuments",
  },
  { labelId: "6. Confirmation", displayOrder: 6, path: "/Confirmation" },
];

export const Stepper: React.FC<Props> = ({ activeStepIndex }) => {
  const current = activeStepIndex;
  const isMobile = useCheckScreenSize({ breakpoint: 850 });

  return (
    <div>
      {isMobile ? (
        <>
          <div style={{ fontWeight: "bold", marginBottom: "15px" }}>
            Step{" "}
            {
              array.find(({ displayOrder }) => displayOrder === current)
                ?.labelId
            }
          </div>
        </>
      ) : (
        <div style={{ marginTop: "30px" }} />
      )}
      <div>
        <Grid container spacing={0}>
          <Grid item xl={10} lg={11} md={11} xs={12}>
            <div
              className={classNames(isMobile && "row", isMobile && "center")}
            >
              {array.map((step) => {
                const label = step.labelId;
                const key = step.displayOrder;
                return (
                  <div key={key}>
                    {!isMobile ? (
                      <>
                        <Grid
                          container
                          spacing={0}
                          aria-current={current === step.displayOrder && "step"}
                        >
                          <Grid item xl={1} lg={1} md={2} sm={1}>
                            {current === step.displayOrder && (
                              <div className="current" aria-label="current">
                                <div className="currentMiddle"></div>
                              </div>
                            )}
                            {step.displayOrder > 0 &&
                              current < step.displayOrder && (
                                <div className="grayCircle"></div>
                              )}
                            {step.displayOrder > 0 &&
                              current > step.displayOrder && (
                                <div className="circle"></div>
                              )}
                          </Grid>
                          <Grid item xl={11} lg={11} md={10} sm={11}>
                            {current === step.displayOrder ? (
                              <>
                                {" "}
                                <div
                                  className={classNames(
                                    current === step.displayOrder
                                      ? "stepText"
                                      : "stepTextNext"
                                  )}
                                  aria-current="step"
                                >
                                  {label}
                                </div>
                              </>
                            ) : (
                              <div
                                className={classNames(
                                  current === step.displayOrder
                                    ? "stepText"
                                    : "stepTextNext"
                                )}
                              >
                                {label}
                              </div>
                            )}
                          </Grid>
                        </Grid>
                        {key <= array.length - 1 && (
                          <div
                            className={classNames(
                              "line",
                              current > step.displayOrder && "blue"
                            )}
                          />
                        )}
                      </>
                    ) : (
                      <div>
                        <div className={classNames("row", "center")}>
                          {current === step.displayOrder && (
                            <div className="current" aria-label="current">
                              <div
                                className="currentMiddle"
                                aria-current="step"
                              ></div>
                            </div>
                          )}
                          {step.displayOrder > 0 &&
                            current < step.displayOrder && (
                              <div className="grayCircle"></div>
                            )}
                          {step.displayOrder > 0 &&
                            current > step.displayOrder && (
                              <div className="circle"></div>
                            )}
                          {key <= array.length - 1 && (
                            <div
                              className={classNames(
                                "mobileLine",
                                current > step.displayOrder ? "blue" : "gray"
                              )}
                            />
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </Grid>
          <br />
        </Grid>
      </div>
    </div>
  );
};
