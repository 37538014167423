import * as React from "react";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";

import { Box } from "@mui/material";

import { ReactComponent as TrashIcon } from "../../../Assets/Trash.svg";
import classNames from "classnames";
import useCheckScreenSize from "../../../utils/checkScreenSize";

interface Props {
  index: number;
  handleDelete: (index: number) => void;
  setShowAlert: (value: boolean) => void;
}

export const DeleteWitnessPopup: React.FC<Props> = ({
  index,
  handleDelete,
  setShowAlert,
}) => {
  const [open, setOpen] = React.useState(false);
  const isMobile = useCheckScreenSize({ breakpoint: 850 });

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteSubmit = () => {
    handleDelete(index);
    setShowAlert(false);
    setOpen(false);
  };

  return (
    <>
      <button
        id="alert-dialog-title"
        aria-label="Delete"
        onClick={handleClickOpen}
        className="editButton"
        type="button"
      >
        <TrashIcon />
      </button>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            m: "auto",
            width: "fit-content",
          }}
          className={classNames(!isMobile && "popup")}
        >
          <DialogTitle id="alert-dialog-title">
            <h2 className="subheader">Delete witness</h2>
          </DialogTitle>
          <DialogContent>
            <div> Would you like to delete the witness?</div>
            <br />
            <br />
            <div style={{ marginTop: "10px" }}>
              <button
                className={classNames(
                  "yellowButton",
                  isMobile && "popupInputMobileItem"
                )}
                onClick={handleDeleteSubmit}
              >
                Delete
              </button>
            </div>

            <div className="secondButtonDiv">
              <button onClick={handleClose} className="secondButton">
                Cancel
              </button>
            </div>
            <br />
          </DialogContent>
        </Box>
      </Dialog>
    </>
  );
};
